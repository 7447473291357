import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    ContainerCard,
    ContainerCards,
    ContainerVisible,
    Titulo,
    Descricao,
    ContainerHidden,
    ContainerInfos,
    Infos,
    ListaProduto,
    ContainerItem,
    Imagem,
    DescricaoProduto,
    BtnQtd,
    TextoBtnQtd,
    Linha,
    BtnResumo,
    TextoResumo,
    Textdicionar,
    InputQtd,
    BtnMinus,
    BtnPlus,
} from "./styles";
import { FiMinus } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ClientesContext } from "../../contexts/clientes";
import { FiAlertTriangle } from "react-icons/fi";
import { FiTrash } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import { FiPlusCircle } from "react-icons/fi";
import ModalCarrinho from "../ModalCarrinhoPedido";

export default function CardsProdutos(props) {
    const { deleteCarrinho, removeNomeCarrinho } = useContext(ClientesContext);
    const [hiddenMenu, setHiddenMenu] = useState(true);
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [cardOpen, setCardOpen] = useState(false);
    const navigation = useNavigate();
    const [openOnePedidoModal, setOpenOnePedidoModal] = useState(false);
    const [openAllPedidoModal, setOpenAllPedidoModal] = useState(false);
    const [pedidoToDelete, setPedidoToDelete] = useState(null);
    const [allPedidoToDelete, setAllPedidoToDelete] = useState(null);
    const isMobilePage = width <= 768;

    let pedidos = props.pedidos;

    function moneyMask(price) {
        let value = "0";
        if (price) {
            value = price.toFixed(2);
        }

        value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
        let result = (+value / 100).toFixed(2);
        result = result < 0 || value === "" || result == null ? 0.0 : result;
        result = result.replace(".", ",");

        return "R$ " + result;
    }

    function sumCalc(type) {
        let result = 0;
        pedidos.map((target) => {
            return (result += target.preco_venda * target.quantidade);
        });
        if (type == "sub") {
            return result;
        } else {
            if (result >= pedidos[0].pedido_frete_gratis) {
                return result;
            } else {
                return result + pedidos[0].valor_frete;
            }
        }
    }

    function calcFreteGratis() {
        let result = 0;
        pedidos.map((target) => {
            return (result += target.preco_venda * target.quantidade);
        });

        if (result - pedidos[0].pedido_frete_gratis < 0) {
            result = Math.abs(result - pedidos[0].pedido_frete_gratis);
        } else {
            result = 0;
        }

        return result;
    }

    const handleOpenModal = (pedido) => {
        setPedidoToDelete(pedido);
        setOpenOnePedidoModal(true);
    };

    const handleCloseModal = () => {
        setOpenOnePedidoModal(false);
        setPedidoToDelete(null);
    };

    const handleConfirm = (element) => {
        if (pedidoToDelete) {
            deleteCarrinho(pedidoToDelete);
        }
        setOpenOnePedidoModal(false);
    };

    const handleOpenAllModal = (pedidos) => {
        setAllPedidoToDelete(pedidos);
        setOpenAllPedidoModal(true);
    };

    const handleConfirmAll = () => {
        if (allPedidoToDelete) {
            removeNomeCarrinho(pedidos);
        }
        setOpenAllPedidoModal(false);
    };

    const handleCloseAllModal = () => {
        setOpenAllPedidoModal(false);
        setAllPedidoToDelete(null);
    };

    if (!isMobilePage) {
        return (
            <ContainerCard>
                <ContainerVisible>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Titulo>{"Pedido " + pedidos[0].nome} </Titulo>
                        <Descricao>Envio em {pedidos[0].prazo_entrega} dias</Descricao>
                    </div>
                    <ContainerHidden
                        style={{
                            display: hiddenMenu ? "none" : "flex",
                        }}
                    >
                        <ContainerInfos style={{ marginBottom: 5 }}>
                            <Infos style={{ cursor: "pointer" }} onClick={() => removeNomeCarrinho(pedidos)}>
                                Remover todos os itens
                            </Infos>
                            <Infos>Pedido Mínimo {moneyMask(pedidos[0].pedido_minimo)}</Infos>
                        </ContainerInfos>
                        {pedidos.map((element, i) => {
                            return (
                                <ListaProduto key={i}>
                                    <ContainerItem>
                                        <Imagem
                                            src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${element.codigo_de_barras}.webp`}
                                        />
                                        <div>
                                            <DescricaoProduto>{element.descricao}</DescricaoProduto>
                                            <DescricaoProduto style={{ marginTop: "0.5vh" }}>
                                                <strong>{moneyMask(element.preco_venda)}</strong>
                                            </DescricaoProduto>
                                        </div>
                                        <BtnQtd>
                                            <BtnMinus
                                                onClick={() => {
                                                    props.handleQuantidade(element, "minus");
                                                }}
                                            >
                                                <FiMinus size={12} color={"#000"} style={{ zIndex: 1 }} />
                                            </BtnMinus>
                                            <InputQtd
                                                type="number"
                                                value={element.quantidade}
                                                onChange={(text) => {
                                                    props.handleQuantidade(element, text.target.value);
                                                }}
                                            />
                                            <BtnPlus
                                                onClick={() => {
                                                    props.handleQuantidade(element, "plus");
                                                }}
                                            >
                                                <FiPlus size={12} color={"#000"} />
                                            </BtnPlus>
                                        </BtnQtd>
                                        <Titulo>{moneyMask(element.quantidade * element.preco_venda)}</Titulo>
                                        <IoCloseCircleOutline
                                            style={{ cursor: "pointer" }}
                                            size={35}
                                            color={"#999999"}
                                            onClick={() => {
                                                deleteCarrinho(element);
                                            }}
                                        />
                                    </ContainerItem>
                                    <Linha />
                                </ListaProduto>
                            );
                        })}
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            <Titulo style={{ fontSize: "1.2rem" }}>{moneyMask(sumCalc("sub"))}</Titulo>
                            <Titulo
                                style={{
                                    fontSize: "1.2rem",
                                    textDecoration: calcFreteGratis() == 0 ? "line-through 2px" : "",
                                }}
                            >
                                Frete: {moneyMask(pedidos[0].valor_frete)}
                            </Titulo>
                        </div>
                    </ContainerHidden>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Descricao>
                            Faltam <strong>{moneyMask(calcFreteGratis())}</strong> para frete grátis
                        </Descricao>

                        <Titulo style={{ fontSize: "1.2rem" }}>{moneyMask(sumCalc("total"))}</Titulo>
                    </div>
                </ContainerVisible>
                {hiddenMenu == true ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <FaAngleDown
                            onClick={() => {
                                setHiddenMenu(!hiddenMenu);
                            }}
                            style={{ cursor: "pointer" }}
                            size={35}
                            color={"var(--background-clientes)"}
                        />
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <FaAngleUp
                            onClick={() => {
                                setHiddenMenu(!hiddenMenu);
                            }}
                            style={{ cursor: "pointer" }}
                            size={35}
                            color={"var(--background-clientes)"}
                        />
                    </div>
                )}
            </ContainerCard>
        );
    } else {
        // MOBILE 768PX
        return (
            <ContainerCard>
                <ContainerVisible>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Titulo>{"Pedido " + pedidos[0].nome} </Titulo>
                        <Descricao>Envio em {pedidos[0].prazo_entrega} dias</Descricao>
                    </div>
                    <ContainerHidden
                        style={{
                            display: hiddenMenu ? "none" : "flex",
                        }}
                    >
                        <ContainerInfos
                            style={{
                                marginTop: "-5px",
                                marginBottom: 10,
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                            }}
                        >
                            <Infos>Pedido Mínimo {moneyMask(pedidos[0].pedido_minimo)}</Infos>
                            <Infos
                                style={{ cursor: "pointer", position: "absolute", right: -15, top: -30 }}
                                onClick={() => handleOpenAllModal(pedidos)}
                            >
                                <FiTrash size={18} color="#875465" />
                            </Infos>
                            {openAllPedidoModal && (
                                <ModalCarrinho
                                    onCancel={handleCloseAllModal}
                                    onConfirm={handleConfirmAll}
                                    message={"Tem certeza que quer excluir esse pedido completo? "}
                                />
                            )}
                        </ContainerInfos>
                        {pedidos.map((element, i) => {
                            return (
                                <ListaProduto key={i}>
                                    <ContainerItem style={{ position: "relative" }}>
                                        <Imagem
                                            src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${element.codigo_de_barras}.webp`}
                                        />
                                        <div>
                                            <DescricaoProduto style={{ fontSize: "12px" }}>
                                                {element.descricao}
                                            </DescricaoProduto>
                                            <DescricaoProduto style={{ marginTop: "0.5vh", fontSize: "10px" }}>
                                                <strong>{moneyMask(element.preco_venda)}</strong>
                                            </DescricaoProduto>
                                        </div>
                                        <BtnQtd>
                                            <BtnMinus
                                                onClick={() => {
                                                    props.handleQuantidade(element, "minus");
                                                }}
                                            >
                                                <FiMinus size={14} color={"#000"} style={{ zIndex: 1 }} />
                                            </BtnMinus>

                                            <InputQtd
                                                type="number"
                                                value={element.quantidade}
                                                onChange={(text) => {
                                                    props.handleQuantidade(element, text.target.value);
                                                }}
                                            />
                                            <BtnPlus
                                                onClick={() => {
                                                    props.handleQuantidade(element, "plus");
                                                }}
                                            >
                                                <FiPlus size={14} color={"#000"} />
                                            </BtnPlus>
                                        </BtnQtd>
                                        {/* <Titulo>{moneyMask(element.quantidade * element.preco_venda)}</Titulo> */}

                                        <div
                                            style={{
                                                position: "absolute",
                                                left: -12,
                                                bottom: 0,
                                                display: "flex",
                                                alignItems: "end",
                                            }}
                                        >
                                            <FiTrash
                                                style={{ cursor: "pointer" }}
                                                size={17}
                                                color={"#999999"}
                                                onClick={() => {
                                                    handleOpenModal(element);
                                                }}
                                            />
                                        </div>
                                    </ContainerItem>
                                    <Linha />
                                </ListaProduto>
                            );
                        })}
                    </ContainerHidden>
                    {openOnePedidoModal && (
                        <ModalCarrinho
                            onCancel={handleCloseModal}
                            onConfirm={handleConfirm}
                            message="Tem certeza que quer excluir esse item do seu pedido?"
                        />
                    )}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", gap: "10px", width: "100%" }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: cardOpen ? "" : "60%",
                                }}
                            >
                                {!cardOpen && moneyMask(calcFreteGratis()) != "R$ 0,00" && (
                                    <FiAlertTriangle fill="#875465" size={22} style={{ padding: "3px" }} />
                                )}
                                {!cardOpen && moneyMask(calcFreteGratis()) == "R$ 0,00" && (
                                    <FiCheck color="#378B19" size={22} style={{ padding: "3px" }} />
                                )}
                                <Descricao style={{ fontSize: "12px", fontStyle: "italic" }}>
                                    {!cardOpen && moneyMask(calcFreteGratis()) != "R$ 0,00" && (
                                        <span>
                                            Faltam <strong>{moneyMask(calcFreteGratis())}</strong> para frete grátis
                                        </span>
                                    )}
                                    {!cardOpen && moneyMask(calcFreteGratis()) == "R$ 0,00" && (
                                        <span>habilitado para frete grátis</span>
                                    )}
                                </Descricao>
                            </div>
                            {cardOpen && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "-13px",
                                        width: "45vw",
                                        marginRight: "-5px",
                                        gap: "3px",
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <FiPlusCircle
                                            color="#3336"
                                            size={20}
                                            onClick={() => navigation("/produtos-clientes")}
                                            style={{ margin: "0px", padding: "0px" }}
                                        />
                                        <Textdicionar>Adicionar produto</Textdicionar>
                                    </div>
                                    <div style={{ paddingLeft: "25px", marginTop: "-15px" }}>
                                        {cardOpen && moneyMask(calcFreteGratis()) != "R$ 0,00" && (
                                            <span
                                                style={{
                                                    fontSize: "9.5px",
                                                    fontWeight: 500,
                                                    color: "#6F6C54",
                                                    width: "100%",
                                                }}
                                            >
                                                Faltam <strong>{moneyMask(calcFreteGratis())}</strong> para frete grátis
                                            </span>
                                        )}
                                        {cardOpen && moneyMask(calcFreteGratis()) == "R$ 0,00" && (
                                            <span
                                                style={{
                                                    fontSize: "10px",
                                                    fontWeight: 500,
                                                    color: "#6F6C54",
                                                }}
                                            >
                                                habilitado para frete grátis
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    {cardOpen && (
                                        <Titulo style={{ fontSize: "10px" }}>
                                            Subtotal: {moneyMask(sumCalc("sub"))}
                                        </Titulo>
                                    )}
                                    {cardOpen && (
                                        <Titulo
                                            style={{
                                                fontSize: "10px",
                                                paddingLeft: "1px",
                                                textDecoration: calcFreteGratis() == 0 ? "line-through 2px" : "",
                                            }}
                                        >
                                            Frete: {moneyMask(pedidos[0].valor_frete)}
                                        </Titulo>
                                    )}
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: cardOpen ? "start" : "end",
                                        marginTop: cardOpen ? "-15px" : "0px",
                                        width: "105%",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: "#615F4A",
                                        }}
                                    >
                                        Total:{" "}
                                    </p>
                                    <Titulo style={{ fontSize: cardOpen ? "10px" : "12px" }}>
                                        {moneyMask(sumCalc("total"))}
                                    </Titulo>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerVisible>
                {hiddenMenu == true ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            position: "relative",
                            width: "100%",
                            height: "5px",
                        }}
                    >
                        <FaAngleDown
                            onClick={() => [setHiddenMenu(!hiddenMenu), setCardOpen(true)]}
                            style={{ cursor: "pointer" }}
                            size={18}
                            color={"var(--background-clientes)"}
                        />
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <FaAngleUp
                            onClick={() => [setHiddenMenu(!hiddenMenu), setCardOpen(false)]}
                            style={{ cursor: "pointer", margin: "5px 10px 0px 0px", padding: 0 }}
                            size={18}
                            color={"var(--background-clientes)"}
                        />
                    </div>
                )}
            </ContainerCard>
        );
    }
}
