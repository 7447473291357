import styled from "styled-components";

export const ContainerGeral = styled.div`
    width: 100%;
`;

export const ContainerHeader = styled.div`
    height: 5vh;
    background-color: var(--branco);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5vw;
`;

export const Image = styled.img`
    width: 7vw;
    margin-left: 2vw;
    cursor: pointer;
`;

export const BtnHeader = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;
`;

export const ContainerMenuProdutos = styled.div`
    transition: all 0.2s;
`;

export const ContainerProdutos = styled.div`
    height: 6vh;
    background-color: var(--background-clientes);
    display: flex;
    align-items: center;
    padding: 0 8vw;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
`;

export const TextoProdutos = styled.h1`
    color: var(--branco);
    font-size: 1.5rem;
    white-space: nowrap;
`;

export const ContainerInputs = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    width: 100%;
    margin-left: 2vw;
`;

export const ContainerInput = styled.div`
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
`;

export const InputProdutos = styled.input`
    color: var(--background-clientes);
    border-radius: 20px;
    margin-left: 1vw;
    height: 3vh;
    border: 2px solid var(--background-clientes);
    transition: all 0.2s;
    &:focus {
        outline: none !important;
        border: 1px solid var(--background-clientes-claro);
        box-shadow: 0 0 10px var(--background-clientes-claro);
    }
`;

export const ContainerMenu = styled.div`
    width: 13vw;
    height: 95vh;
    border-radius: 20px;
    border-bottom-right-radius: 180px;
    background-color: var(--background-clientes);
    z-index: 2;
    position: absolute;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    gap: 1vh;
`;

export const ContainerItensMenu = styled.button`
    display: flex;
    flex-direction: row;
    height: 5vh;
    align-items: center;
    gap: 1vw;
    border-radius: 20px;
    padding: 0 1vw;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        background-color: var(--background-clientes-escuro);
    }
`;

export const TextoItensMenu = styled.h1`
    font-size: 1.2rem;
    color: #fff;
`;

export const ContainerCount = styled.div`
    position: absolute;
    font-size: 0.6rem;
    font-weight: bold;
    color: #fff;
    background-color: #ef6969;
    right: 0;
    bottom: 1vh;
    padding: 4px 8px;
    border-radius: 50%;
    display: flex;
    text-align: center;
    transition: all 0.2s;
`;
