import styled from "styled-components";

export const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const Modal = styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 80vw;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
    border-bottom-right-radius: 30px;
    > div {
        > button {
            height: 30px;
            border-radius: 3px;
            min-width: 70px;
            padding: 6px 12px;
            font-family: "Poppins", sans-serif;
            cursor: pointer;
            &:hover {
                transform: scale(1.025);
            }
        }
    }
`;

export const Titulo = styled.h2`
    color: #2228;
    font-family: "Poppins", sans-serif;
`;

export const Text = styled.p`
    color: #1f2133;
    font-family: "Poppins", sans-serif;
`;
