import React from "react";
import { Container, ContainerStatus, TextoStatus, TextoComp } from "./styles";
import { BsBagCheckFill } from "react-icons/bs";
import { FaWindowClose } from "react-icons/fa";

export default function MsgError(props) {
    return (
        <Container>
            <ContainerStatus style={{ backgroundColor: props.isPositive ? "#378b19" : "#ef6969" }}>
                {props.isPositive ? (
                    <BsBagCheckFill size={50} color={"#fff"} />
                ) : (
                    <FaWindowClose size={50} color={"#fff"} />
                )}
                <TextoStatus>{props.mensagem1}</TextoStatus>
                <TextoComp>{props.mensagem2}</TextoComp>
            </ContainerStatus>
        </Container>
    );
}
