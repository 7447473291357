import React from 'react'
import { PiPrinterFill } from "react-icons/pi";
import styles from './ImprimirFornecedor.module.css'

const ImprimirFornecedor = () => {
    const handleImprimir = () => {
        window.print();
      };

  return (
    <div className={styles.impressao}>
         <button onClick={handleImprimir}><PiPrinterFill/></button>
       
    </div>
  )
}

export default ImprimirFornecedor
