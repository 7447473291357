import React, { useContext, useState, useEffect } from "react";
import { ClientesContext } from "../../contexts/clientes";
import { AuthContext } from "../../contexts/auth";
import HeaderClientes from "../../components/HeaderClientes";
import {
    ContainerGeral,
    TituloPagina,
    ContainerCarrinho,
    ContainerPedidos,
    ContainerResumo,
    CardResumo,
    TitleResumo,
    DescricaoResumo,
    BtnResumo,
    TextoResumo,
    ContainerDescricaoResumo,
    ContainerBtn,
} from "./styles";
import CardPedidos from "../../components/CardPedidos";
import { FaRegCircleCheck } from "react-icons/fa6";
import HTTP from "../../http/http";
import { HiMiniArrowLeft } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import FooterMobile from "../../components/MenuFooterMobile";
import HeaderComponentMobile from "../../components/HeaderComponentMobile";
import { FaRegStar } from "react-icons/fa";
import { PiShoppingCartSimpleFill } from "react-icons/pi";

export default function Clientes() {
    const { createListaPedidos, storePedidos, pedidos } = useContext(ClientesContext);
    const { token, user, device } = useContext(AuthContext);
    // const [carrinho, setCarrinho] = useState([]);
    const [listaPedidos, setListaPedidos] = useState([]);
    const navigation = useNavigate();

    useEffect(() => {
        HTTP.get(`${process.env.REACT_APP_BASE_HTTP}/pedidos`, {
            headers: { authorization: "Bearer " + token },
            params: {
                id_usuario: user.id_usuario,
            },
        })
            .then((response) => {
                storePedidos(response.data);
                setListaPedidos(createListaPedidos(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (pedidos.length > 0) {
            setListaPedidos(createListaPedidos(pedidos));
        }
    }, [pedidos]);

    if (device != "mobile") {
        return (
            <div>
                <HeaderClientes />
                <TituloPagina>{"Meus Pedidos"}</TituloPagina>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {pedidos.length > 0 ? (
                        <ContainerGeral>
                            <ContainerCarrinho>
                                <ContainerPedidos>
                                    {Object.keys(listaPedidos).map((element, i) => {
                                        return <CardPedidos pedidos={listaPedidos[element]} key={i} />;
                                    })}
                                </ContainerPedidos>
                            </ContainerCarrinho>
                        </ContainerGeral>
                    ) : (
                        <ContainerGeral>
                            <h1 style={{ color: "#ef6969" }}>Não há itens no carrinho</h1>
                        </ContainerGeral>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <>
                <HeaderComponentMobile title="MEUS PEDIDOS" />

                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {pedidos.length > 0 ? (
                        <ContainerGeral
                            style={{
                                padding: "0px",
                                width: "80vw",
                                flexDirection: "column",
                            }}
                        >
                            <ContainerCarrinho>
                                <ContainerPedidos>
                                    {Object.keys(listaPedidos).map((element, i) => {
                                        return (
                                            <CardPedidos
                                                // handleRepetirCompra={repetirCompra}
                                                pedidos={listaPedidos[element]}
                                                key={i}
                                            />
                                        );
                                    })}
                                </ContainerPedidos>
                            </ContainerCarrinho>
                        </ContainerGeral>
                    ) : (
                        <ContainerGeral>
                            <h1 style={{ color: "#ef6969" }}>Não há itens no carrinho</h1>
                        </ContainerGeral>
                    )}
                </div>
                <FooterMobile />
            </>
        );
    }
}
