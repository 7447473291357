import styled from "styled-components";

export const ContainerModal = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
`;

export const ContainerMSG = styled.div`
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    z-index: 999;
    background-color: #fff;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const TextoModal = styled.h1`
    margin: 0;
    color: var(--background-clientes-escuro);
    text-decoration: underline;
`;

export const H2Modal = styled.h2`
    margin: 1vh 1vw;
    color: var(--background-clientes-escuro);
`;

export const ButtonModal = styled.h2`
    cursor: pointer;
    padding: 0.5vh 1vw;
    border: 1px solid var(--background-clientes-claro);
    background-color: var(--background-clientes-escuro);
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
    &:hover {
        background-color: var(--background-clientes);
    }
`;
