import styled from "styled-components";

export const ButtonFilter = styled.button`
    border: none;
    cursor: pointer;
    color: #0005;
    background-color: transparent;
    font-weight: bold;
    width: 50%;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    height: 25px;
    display: flex;
    max-width: 150px;
`;

export const ContainerCards = styled.div`
    background-color: #fff;
    width: max-content !important;
    padding: 0;
    border-radius: 5%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.9);
    }
`;

export const ContainerCardsMobile = styled.div`
    padding: 0;
    border-radius: 20%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
    height: 60px;
`;

export const ContainerMobile = styled.div`
    width: 100vw;
    height: 92vh;
    position: relative;
    overflow: hidden;
`;

export const ContainerImg = styled.div`
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
`;

export const ContainerCategorias = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`;

export const ContainerCategoriasMobile = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
`;

export const ContainerFiltroPesquisa = styled.div`
    display: flex;
    align-items: center;
    margin: -5px auto 15px auto;
    width: 90%;
    border-radius: 30px;
    background-color: #d9d9d9ba;
    border: none;
    height: 30px;
`;

export const ContainerInput = styled.div`
    display: flex;
    width: 100%;
`;

export const InputProdutos = styled.input`
    display: flex;
    border: none;
    width: 100%;
    height: 26px;
    margin-top: 3px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 3px;
    background-color: transparent;
    &:focus {
        outline: none;
    }
`;

export const ImgCard = styled.img`
    max-width: 6vw;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    border-radius: 5%;
`;

export const ImgCardMobile = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20%;
    z-index: 1;
`;

export const TextCard = styled.h1`
    color: var(--background-clientes-escuro);
    font-size: 11px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #484739;
    word-break: break-all;
    padding: 5px 0;
    margin: 0;
`;

export const ContainerTextMobile = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -5%;
    z-index: 2;
    background-color: #fff;
    border-radius: 100px;
`;

export const TextCardMobile = styled.h1`
    color: #fff;
    @media (max-width: 350px) {
        font-size: 9px;
    }
    font-size: 11px;
    text-align: center;
    color: #484739;
    word-break: break-all;
    padding: 4px 0;
    margin: 0;
    overflow: scroll;
`;
