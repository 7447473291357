import styled from "styled-components";

export const ContainerGeral = styled.div`
    padding: 0;
    margin-top: 1vh;
    display: grid;
    grid-template-columns: 0.65fr 0.35fr;
    justify-content: space-between;
    width: 80%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 5px;
        overflow-y: auto;
        padding-bottom: 50px;
        width: 100%;
    }
`;

export const TituloPagina = styled.h1`
    color: var(--background-clientes-escuro);
    font-size: 1.4rem;
    margin-top: 3vh;
    padding: 0 15vw;
`;

export const ContainerCarrinho = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
export const ContainerPedidos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    overflow-y: auto;
    padding-bottom: 20px;
    overflow-y: hidden;
    width: 100%;
`;

export const ContainerResumo = styled.div`
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 80%;
`;

export const CardResumo = styled.div`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 1.5vh 1vw;
    background-color: #fff;
    border: none;
    width: 100%;
`;

export const TitleResumo = styled.h1`
    font-size: 1.2rem;
    @media (max-width: 768px) {
        font-family: "Poppins", sans-serif;
        font-size: 14px;
    }
`;

export const TotalResumo = styled.h1`
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
`;

export const ContainerDescricaoResumo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3vw;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-right: 20px;
    position: relative;
`;

export const DescricaoResumo = styled.h2`
    font-size: 1rem;
    color: var(--background-clientes-claro);
    margin: 0;
`;

export const ContainerBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3vh;
    gap: 10px;
`;

export const BtnResumo = styled.button`
    border-radius: 20px;
    border: 1px solid;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.5s;
    border: 1px solid #206109;
    background-color: #378b19;
    &:hover {
        position: relative;
        top: -1px;
        box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.25);
    }
    @media (max-width: 768px) {
        background-color: #8eb26a;
        border: none;
    }
`;

export const TextoResumo = styled.h1`
    font-size: 1.1rem;
    color: #fff;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        text-transform: lowercase;
    }
`;
