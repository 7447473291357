import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
`;

export const ContainerStatus = styled.div`
    position: absolute;
    left: 50%;
    right: 50%;
    top: 25%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    padding: 2% 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
`;

export const TextoStatus = styled.h3`
    margin: 0;
    font-size: 25px;
    margin-top: 5px;
    color: #fff;
    text-align: center;
`;

export const TextoComp = styled.h3`
    margin: 0;
    font-size: 20px;
    margin-top: 1px;
    color: #fff;
    text-align: center;
`;
