import React, { useContext, useEffect, useState } from "react";
import { ContainerModal, ContainerMSG, TextoModal, H2Modal, ButtonModal } from "./styles";

import { useNavigate } from "react-router-dom";

export default function TelaRetorno() {
    const navigation = useNavigate();

    return (
        <ContainerModal>
            <ContainerMSG>
                <TextoModal>Seu login foi expirado! </TextoModal>
                <H2Modal>Volte para a tela incial e faça o login novamente.</H2Modal>
                <ButtonModal
                    onClick={() => {
                        navigation("/");
                    }}
                >
                    Ok!
                </ButtonModal>
            </ContainerMSG>
        </ContainerModal>
    );
}
