import styled from "styled-components";
import { FiMinus } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";

export const IconMinus = styled(FiMinus)`
    font-size: 12px;
    z-index: 1;
    @media (max-width: 768px) {
        color: #fff;
    }
    @media (min-width: 768px) {
        color: #000;
    }
`;

export const IconPlus = styled(FiPlus)`
    font-size: 12px;
    @media (max-width: 768px) {
        color: #fff;
    }
    @media (min-width: 768px) {
        color: #000;
    }
`;

export const ContainerCard = styled.div`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    background-color: #fff;
    border: none;
    transition: all 0.5s;
    vertical-align: top;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 40vw;
    }
    @media (max-width: 912px) and (max-height: 1370px) {
        width: 40vw;
    }
`;

export const ContainerProduto = styled.div`
    display: grid;
`;

export const ContainerTexto = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
    margin-bottom: 10px;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }
`;

export const ContainerTextos = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: space-around;
    /* @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        justify-content: space-around;
    } */
`;

export const ContainerProdutos = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;
export const ContainerPrecos = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.2vw;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 425px) {
        justify-content: space-between;
    }

    @media (max-width: 768px) {
        display: flex;
        font-size: 10px;
        justify-content: space-evenly;
        width: 100%;
    }
    @media (min-width: 768px) {
        width: 100%;
        justify-content: space-evenly;
    }
`;

export const ContainerBtns = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
    max-width: 95%;
    align-items: center;
    justify-content: center;
    @media (max-width: 425px) {
        max-width: 100%;
        justify-content: space-between;
    }
    @media (min-width: 425px) {
        justify-content: space-around;
    }
`;

export const ContainerUnitario = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 425px) {
        background-color: #f5f5f5;
        border-radius: 10px;
        width: 50%;
        justify-content: center;
        > h1 {
            font-size: 10px;
        }
    }
`;

export const Img = styled.img`
    object-fit: contain;
    overflow: hidden;
    @media (max-width: 425px) {
        height: 8vh;
    }
    @media (min-width: 425px) {
        height: 12vh;
        max-width: 30vw;
    }
    @media (min-width: 768px) {
        height: 13vh;
    }
`;

export const Titulo = styled.h1`
    font-size: 0.9rem;
    color: var(--background-clientes-escuro);
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    text-decoration: underline;
    text-decoration: none;
    font-weight: 600;
    @media (max-width: 425px) {
        font-size: 0.8rem;
        text-align: center;
        min-height: 40px;
    }
`;

export const Descricao = styled.h2`
    font-size: 0.8rem;
    color: var(--cinza-claro);
    font-weight: 400;
    overflow-y: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const DescricaoFrete = styled.h2`
    font-size: 0.8rem;
    color: var(--cinza-claro);
    font-weight: 400;
    overflow-y: auto;
    margin: 0;
    text-align: center;
    @media (max-width: 425px) {
        color: #999999;
        font-family: "Roboto", sans-serif;
        font-size: 11px;
        margin: 5px 0px;
    }
`;

export const Codigo = styled.h2`
    font-size: 0.9em;
    color: var(--cinza-claro);
    max-width: 9vw;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding-top: 5px;
`;

export const Preco = styled.h1`
    font-size: 0.8rem;
    font-weight: 800;
    color: var(--background-clientes-escuro);
    margin: 0;
    @media (max-width: 425px) {
        font-weight: 700px;
        font-family: "Inter", sans-serif;
    }
`;

export const PrecoKG = styled.h3`
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--background-clientes-escuro);
    margin: 0;
`;

export const BtnQtd = styled.div`
    padding: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    height: 3vh;
    @media (max-width: 425px) {
        background-color: #875465;
        width: 45%;
        justify-content: space-around;
        padding: 5px 0px;
    }
    @media (min-width: 425px) {
        width: 40%;
        background-color: #875465;
        justify-content: space-around;
        padding: 0px 0px;
    }
    @media (max-width: 600px) {
        display: none;
    }
    @media (min-width: 600px) {
        display: flex;
    }
    @media (min-width: 768px) {
        background-color: var(--branco);
        width: 50%;
        justify-content: space-between;
        border: 1px solid var(--background-clientes-claro);
    }
`;

export const TextoBtnQtd = styled.h2`
    font-size: 0.8rem;
`;

export const InputQtd = styled.input`
    width: 2vw;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
    border-top: 1px solid var(--background-clientes-claro);
    border-bottom: 1px solid var(--background-clientes-claro);
    outline-color: var(--background-clientes-claro);
    @media (max-width: 768px) {
        background-color: #875465;
        color: #fff;
        border: transparent;
    }
    @media (min-width: 768px) {
        background-color: transparent;
        color: #000;
        border: none;
    }
`;

export const BtnMinus = styled.button`
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    align-items: center;
    border: none;
    @media (max-width: 768px) {
        border: none;
    }
`;

export const BtnPlus = styled.button`
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    align-items: center;
    border: none;
`;

export const BtnAdc = styled.button`
    width: 70%;
    border: 2px solid #000000;
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
    z-index: 1;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        color: var(--branco);
    }
    &:hover {
        background-color: var(--background-clientes-claro);
    }
`;

export const BtnAdcMobile = styled.button`
    border: none;
    border-radius: 20px;
    background-color: #875465 !important;
    cursor: pointer;
    z-index: 1;
    color: #fff !important;
    height: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &:hover {
        color: var(--branco);
    }
    &:hover {
        background-color: var(--background-clientes-claro);
    }
    @media (max-width: 425px) {
        color: #fff;
        width: 100%;
        font-weight: 500;
        height: 23px;
        border: none;
    }
    @media (min-width: 425px) {
        width: 70%;
        background-color: #875465;
        color: #fff;
        height: 4vh;
    }
    @media (min-width: 768px) {
        width: 16vw;
        background-color: var(--branco);
        font-size: 0.8em;
        color: var(--background-clientes-escuro);
        font-weight: 800;
        margin: 0 auto;
    }
`;

export const SpanUnidade = styled.span`
    background-color: #f5f5f5;
    display: flex;
    font-size: 14px;
    @media (max-width: 600px) {
        font-size: 10px;
    }
`;
