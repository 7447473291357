import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import TelaRetorno from "../../components/TelaRetorno";

export default function ProtectedRoute() {
    const { user, token } = useContext(AuthContext);

    if (!user || !token) return <Navigate to="/" />;

    let tokenExpirado = <div></div>;

    if (new Date().getTime() >= new Date(user.expire_time * 1000).getTime()) {
        tokenExpirado = <TelaRetorno />;
    }

    return (
        <div>
            {tokenExpirado}

            <Outlet />
        </div>
    );
}
