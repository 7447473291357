import React, { useState} from 'react'
import { IoNewspaperOutline } from "react-icons/io5";
import { LuLayoutGrid,  LuArrowUpSquare } from "react-icons/lu";
import { FiPlusSquare } from "react-icons/fi";
import { BiNetworkChart } from "react-icons/bi";
import { AiOutlineLineChart } from "react-icons/ai";
import styles from './styles.module.css'
import {
   LinkNavegacao,
   LinksMenu,
   Sombra,
   AncoraNav,
   SpanIcon,
   TextLink,
   LinkReact
  } from './style'

const MenuNavegacao = () => {
  const [ativo, setAtivo] = useState(false);
  const handleItemClick = () => {
    setAtivo(!ativo); //
  };

  return (
    <div className={`${styles.section_aside} ${ativo ? styles.ativo : ''}`} onClick={handleItemClick}>
      <LinkNavegacao>
        <LinksMenu>
          <Sombra>
                <AncoraNav href='#'>
                  <SpanIcon>
                   <IoNewspaperOutline/>
                  </SpanIcon>
                  <TextLink>
                     <LinkReact to="/fornecedores">Pedidos</LinkReact>
                  </TextLink>
                </AncoraNav>
          </Sombra>
        </LinksMenu>

        <LinksMenu>    
          <Sombra>
            <AncoraNav href='#'>  
                <SpanIcon>
                   <LuLayoutGrid/>
                </SpanIcon>
                  <TextLink>Estabelecimentos</TextLink>
                </AncoraNav>
            </Sombra>
        </LinksMenu>

           
        <LinksMenu> 
          <Sombra>
            <AncoraNav href='#'>
              <SpanIcon>
                  <FiPlusSquare/>
                </SpanIcon>
                <TextLink>Produtos</TextLink>
            </AncoraNav>
          </Sombra>
        </LinksMenu>
        <LinksMenu> 
          <Sombra>
            <AncoraNav href='#'>
              <SpanIcon>
                  <LuArrowUpSquare/>
              </SpanIcon>
              <TextLink>Preços</TextLink>
            </AncoraNav>
          </Sombra>
        </LinksMenu>
        <LinksMenu> 
          <Sombra>
            <AncoraNav href='#'>
              <SpanIcon>
                <BiNetworkChart/>
              </SpanIcon>
              <TextLink>
                <LinkReact to="/demanda">Demanda</LinkReact></TextLink>
            </AncoraNav>
          </Sombra>
        </LinksMenu>
        <LinksMenu> 
          <Sombra>
            <AncoraNav href='#'>
              <SpanIcon>
                 <AiOutlineLineChart/>
              </SpanIcon>
                <TextLink> Análises</TextLink>
            </AncoraNav>
           </Sombra>
        </LinksMenu>
      </LinkNavegacao>
    </div>
  )
}

export default MenuNavegacao
