// import Routes from "./routes";
import AuthProvider from "./contexts/auth";
import ClientesProvider from "./contexts/clientes";
import FornecedoresProvider from "./contexts/fornecedores";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import Login from "./pages/Login";
import ProdutosClientes from "./pages/ProdutosClientes";
import CarrinhoClientes from "./pages/CarrinhoClientes";
import PedidosClientes from "./pages/PedidosClientes";
import Fornecedores from "./pages/Fornecedores1";
import ForncedoresDemanda from "./pages/Fornecedores1/ForncedoresDemanda";
import PedidosFavoritos from "./pages/ProdutosFavoritos";
import ProtectedRoute from "./pages/ProtectedRoute";

export default function App() {
    useEffect(() => {
        const preventPullToRefresh = (event) => {
            if (window.scrollY === 0) {
                event.preventDefault();
            }
        };

        const preventReload = (event) => {
            event.preventDefault();
            event.returnValue = "";
        };

        window.addEventListener("touchmove", preventPullToRefresh, { passive: false });
        window.addEventListener("beforeunload", preventReload);

        return () => {
            window.removeEventListener("touchmove", preventPullToRefresh);
            window.removeEventListener("beforeunload", preventReload);
        };
    }, []);

    return (
        <AuthProvider>
            <ClientesProvider>
                <FornecedoresProvider>
                    <Routes>
                        <Route index path="/" element={<Login />} />

                        <Route path="produtos-clientes" element={<ProtectedRoute />}>
                            <Route path="" element={<ProdutosClientes />} />
                        </Route>

                        <Route path="carrinho-clientes" element={<ProtectedRoute />}>
                            <Route path="" element={<CarrinhoClientes />} />
                        </Route>

                        <Route path="pedidos-clientes" element={<ProtectedRoute />}>
                            <Route path="" element={<PedidosClientes />} />
                        </Route>

                        <Route path="pedidos-favoritos" element={<ProtectedRoute />}>
                            <Route path="" element={<PedidosFavoritos />} />
                        </Route>

                        <Route path="fornecedores" element={<ProtectedRoute />}>
                            <Route path="" element={<Fornecedores />} />
                        </Route>

                        <Route path="demanda" element={<ProtectedRoute />}>
                            <Route path="" element={<ForncedoresDemanda />} />
                        </Route>
                    </Routes>
                </FornecedoresProvider>
            </ClientesProvider>
        </AuthProvider>
    );
}
