import React, { useState, useContext, useEffect } from "react";
import {
    ContainerHeader,
    Image,
    BtnHeader,
    ContainerGeral,
    ContainerProdutos,
    TextoProdutos,
    InputProdutos,
    ContainerInputs,
    ContainerInput,
    ContainerMenuProdutos,
    ContainerMenu,
    ContainerItensMenu,
    TextoItensMenu,
    ContainerCount,
} from "./styles";
import logo from "../../assets/img/logo.png";
import { FiMenu } from "react-icons/fi";
import { FaShoppingCart } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FiXCircle } from "react-icons/fi";
import { FaClipboardCheck } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { ClientesContext } from "../../contexts/clientes";
import DatalistInput from "react-datalist-input";
import "react-datalist-input/dist/styles.css";

export default function HeaderClientes() {
    const { logoutApp, userDimensions } = useContext(AuthContext);
    const { changeFiltro, listaFornecedores, searchProdutos, carrinho, selectProduct } = useContext(ClientesContext);
    const [showMenu, setShowMenu] = useState(false);
    const location = useLocation();
    const navigation = useNavigate();
    const [selectedMarca, setSelectedMarca] = useState(null);
    const [selectedText, setSelectedText] = useState("");
    const [showCount, setShowCount] = useState(false);

    function logout() {
        logoutApp();
        navigation("/");
    }

    useEffect(() => {
        if (carrinho.length > 0) setShowCount(true);
    }, [carrinho]);

    function resetProdutos() {
        navigation("/produtos-clientes");
        changeFiltro("reset", "reset");
        setSelectedText("");
        setSelectedMarca(null);
        selectProduct(null);
    }

    return (
        <ContainerGeral>
            <ContainerHeader>
                <BtnHeader
                    onClick={() => {
                        setShowMenu(!showMenu);
                    }}
                >
                    {showMenu === true ? (
                        <FiXCircle color="var(--background-clientes)" size={30} />
                    ) : (
                        <FiMenu color="var(--background-clientes)" size={30} />
                    )}
                </BtnHeader>
                <Image onClick={() => navigation("/produtos-clientes")} src={logo} />
                <BtnHeader
                    onClick={() => {
                        navigation("/carrinho-clientes");
                    }}
                    style={{ position: "absolute", right: "8vw" }}
                >
                    <FaShoppingCart color="var(--background-clientes)" size={userDimensions.height * 0.035} />
                    {showCount && <ContainerCount>{carrinho.length}</ContainerCount>}
                </BtnHeader>
            </ContainerHeader>
            <ContainerMenuProdutos>
                {showMenu && (
                    <ContainerMenu>
                        <ContainerItensMenu
                            style={{
                                backgroundColor:
                                    location.pathname == "/produtos-clientes"
                                        ? "var(--background-clientes-escuro)"
                                        : "",
                            }}
                            onClick={() => {
                                navigation("/produtos-clientes");
                            }}
                        >
                            <FaSearch color="var(--branco)" size={20} />
                            <TextoItensMenu>Produtos</TextoItensMenu>
                        </ContainerItensMenu>
                        <ContainerItensMenu
                            onClick={() => {
                                navigation("/carrinho-clientes");
                            }}
                            style={{
                                backgroundColor:
                                    location.pathname == "/carrinho-clientes"
                                        ? "var(--background-clientes-escuro)"
                                        : "",
                            }}
                        >
                            <FaShoppingCart color="var(--branco)" size={20} />
                            <TextoItensMenu>Carrinho</TextoItensMenu>
                        </ContainerItensMenu>
                        <ContainerItensMenu
                            onClick={() => {
                                navigation("/pedidos-clientes");
                            }}
                            style={{
                                backgroundColor:
                                    location.pathname == "/pedidos-clientes" ? "var(--background-clientes-escuro)" : "",
                            }}
                        >
                            <FaClipboardCheck color="var(--branco)" size={20} />
                            <TextoItensMenu style={{ fontSize: "1rem" }}>Meus Pedidos</TextoItensMenu>
                        </ContainerItensMenu>
                        <ContainerItensMenu
                            onClick={() => {
                                logout();
                            }}
                        >
                            <MdLogout color="var(--branco)" size={20} />
                            <TextoItensMenu></TextoItensMenu>
                        </ContainerItensMenu>
                    </ContainerMenu>
                )}
                <ContainerProdutos style={{ marginLeft: showMenu ? "13vw" : "" }}>
                    <TextoProdutos
                        onClick={() => {
                            location.pathname == "/produtos-clientes" && resetProdutos();
                        }}
                        style={{ cursor: location.pathname == "/produtos-clientes" && "pointer" }}
                    >
                        {location.pathname == "/produtos-clientes" && "Produtos"}
                        {location.pathname == "/carrinho-clientes" && "Carrinho"}
                        {location.pathname == "/pedidos-clientes" && "Meus Pedidos"}
                    </TextoProdutos>
                    <ContainerInputs>
                        {location.pathname == "/produtos-clientes" && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "1vw",
                                    width: "100%",
                                }}
                            >
                                <ContainerInput>
                                    <FaSearch
                                        style={{ position: "absolute", left: userDimensions.width * 0.015 }}
                                        color="var(--background-clientes)"
                                        size={20}
                                    />
                                    <InputProdutos
                                        placeholder="Digite uma palavra chave ex. “bacon”"
                                        type="text"
                                        onChange={(text) => [
                                            searchProdutos(text.target.value),
                                            setSelectedText(text.target.value),
                                        ]}
                                        value={selectedText}
                                        style={{
                                            width: userDimensions.width * 0.18,
                                            paddingLeft: userDimensions.width * 0.035,
                                        }}
                                    />
                                </ContainerInput>
                                <DatalistInput
                                    placeholder="Filtrar Fornecedor"
                                    onSelect={(a) => {
                                        changeFiltro("nome", a.value);
                                        setSelectedMarca(a.value);
                                    }}
                                    value={selectedMarca}
                                    items={listaFornecedores.map((item, index) => {
                                        return {
                                            key: index,
                                            value: item.value,
                                        };
                                    })}
                                    style={{ width: userDimensions.width * 0.12 }}
                                />
                                <button
                                    style={{
                                        borderRadius: "10px",
                                        border: "none",
                                        cursor: "pointer",
                                        backgroundColor: "var(--background-clientes-claro)",
                                        color: "#fff",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                        resetProdutos();
                                    }}
                                >
                                    Remover Filtros
                                </button>
                            </div>
                        )}
                    </ContainerInputs>
                </ContainerProdutos>
            </ContainerMenuProdutos>
        </ContainerGeral>
    );
}
