import React, { createContext, useState, useEffect } from "react";

export const FornecedoresContext = createContext({});

function FornecedoresProvider({ children }) {
    const [testeFornecedores, setTesteFornecedores] = useState("testeContextFornecedores");

    return (
        <FornecedoresContext.Provider
            value={{
                testeFornecedores,
            }}
        >
            {children}
        </FornecedoresContext.Provider>
    );
}

export default FornecedoresProvider;
