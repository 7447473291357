import React from 'react'
import MenuNavegacao from '../MenuNavegacaoFornecedores';
import DemandaFornecedores from '../DemandaFornecedores/DemandaFornecedores'
import styles from './ContainerDemanda.module.css'

const ContainerDemanda = () => {
  return (
    <div className={styles.demanda_container}>
            <MenuNavegacao className={styles.aside_navegation}/>
        <div className={styles.demanda_painel}>
            <DemandaFornecedores/>
        </div>
    </div>

  )
}

export default ContainerDemanda
