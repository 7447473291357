import styled from "styled-components";

export const Container = styled.div`
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: grid;
    grid-template-columns: 1.1fr 1fr;
`;

export const ContainerMobile = styled.div`
    background-color: #875465;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
`;

export const ContainerLogin = styled.div`
    background-color: #fff;
    width: 50vw;
    height: 100vh;
`;

export const ContainerSign = styled.div`
    background-color: var(--background-clientes);
    border-bottom-left-radius: 70%;
    display: flex;
    justify-content: center;
`;

export const ContainerItems = styled.div`
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContainerItemsMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextReset = styled.h1`
    display: none;
`;

export const DivInputs = styled.div`
    display: flex;
    flex-direction: column;
    height: 23vh;
    width: 100%;
    gap: 15px;
    padding-top: 15%;

    > div {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        > h2 {
            padding-left: 10px;
            color: #f4f4f4;
            font-size: 14px;
            text-align: start;
            margin-bottom: 5px;
            margin-top: 0px;
            font-family: "Sora", sans-serif;
            font-weight: 600;
            font-style: normal;
        }
    }
`;

export const TextSign = styled.h1`
    color: #fff;
`;

export const TextMobile = styled.h1`
    color: #f4f4f4;
    font-size: 13px;
    font-family: "Sora", sans-serif;
    text-align: center;
    > span {
        border-bottom: 2px solid #f4f4f4 !important;
        text-decoration: none !important;
        padding-bottom: 2px;
        > a {
            text-decoration: none !important;
            list-style: none;
            color: #eee;
        }
    }
`;

export const BtnSign = styled.a`
    background-color: var(--background-clientes-claro);
    border: 1px solid var(--background-clientes-escuro);
    color: #fff;
    padding: 15px 40px;
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: 600;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-decoration: none;
`;

export const BtnLogin = styled.button`
    margin-top: 1.5rem;
    background-color: var(--background-clientes);
    border: 2px solid var(--background-clientes-escuro);
    color: #fff;
    padding: 15px 40px;
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: 600;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        background-color: var(--background-clientes-claro);
    }
`;

export const BtnLoginMobile = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    font-size: 20px;
    background-color: #fff;
    color: #593843;
    height: 45px;
    border-radius: 12px;
    font-weight: 700;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.5s;
    font-family: "Sora", sans-serif;
    width: 45%;
    max-width: 250px;
    margin: 0 auto;
    margin-top: 30px;

    border: none;
    &:hover {
        background-color: var(--background-clientes-claro);
    }
`;

export const ImagemSimplificada = styled.img`
    width: 8vw;
`;

export const Imagem = styled.img`
    width: 20vw;
`;

export const InputLogin = styled.input`
    width: 18vw;
    height: 40px;
    border-radius: 20px;
    border: 3px solid var(--background-clientes);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0 1rem;
    margin-top: 1.2rem;
`;

export const InputLoginMobile = styled.input`
    display: flex;
    min-width: 200px;
    width: 45vw;
    height: 35px;
    border-radius: 12px;
    background: #fff;
    border: none;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    padding-left: 10px;
    margin: 0 auto;
    font-family: "Sora", sans-serif;
    &:focus {
        outline: none;
    }
`;
