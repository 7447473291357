import React, { useContext, useEffect, useState } from "react";
import {
    ButtonsMobile,
    ContainerGeral,
    ContainerGeralMobile,
    ContainerCards,
    ContainerCardsMobile,
    ContainerMainMobile,
    ContainerProdutoMobile,
    ContainerImage,
    ContainerProduto,
    ContainerTextosMobile,
    ContainerHeaderDetalhe,
    DivDuvidas,
    TituloPagina,
    IconMinus,
    IconPlus,
    ImagemProduto,
    ContainerTexto,
    ContainerPrecos,
    TituloProduto,
    InformacoesProduto,
    PrecoPrincipal,
    InformacoesPreco,
    BtnQtd,
    InputQtd,
    BtnMinus,
    BtnPlus,
    BtnAdc,
    BtnAdcMobile,
    BtnContato,
    ContainerRelacionados,
    TextoBtnTitulo,
    ContainerPagination,
    BotaoPagination,
    ContainerButtonsPagination,
    ContainerPrevNext,
    ContainerClose,
    SubCtnTextos,
    ButtonsPagination,
} from "./styles";

import CardProduto from "../CardsProdutos";
import { AuthContext } from "../../contexts/auth";
import { ClientesContext } from "../../contexts/clientes";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import axios from "axios";
import HTTP from "../../http/http";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Pagination from "@mui/material/Pagination";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/styles";
import MsgError from "../../components/MsgError";
import { useNavigate } from "react-router-dom";
import TelaRetorno from "../TelaRetorno";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoIosStarOutline } from "react-icons/io";
import { HiMiniArrowLeft } from "react-icons/hi2";
import logoAlimenzMobile from "../../assets/img/logo.png";
import iconeLogo from "../../assets/img/logo-simplificada.png";
import SubCardsProdutos from "../SubCardProduto";
import starYellow from "../../assets/img/staryellow.png";
import ModalMensagem from "../../components/ModalCarrinhoPedido";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiPaginationItem-icon": {
            color: "var(--background-clientes-escuro)",
        },
        "& .MuiPaginationItem-root": {
            color: "var(--background-clientes-escuro)",
        },
    },
}));

export default function ProdutosSugeridos({ produto }, props) {
    const { token, userDimensions, device } = useContext(AuthContext);
    const {
        chosenProduct,
        selectProduct,
        storeProdutos,
        storePaginacao,
        produtosFiltrados,
        produtos,
        filtroTexto,
        storeCarrinho,
        changeFiltro,
        selectProductEnter,
        showHeader,
        setShowHeader,
        favoritos,
        handleFavorito,
    } = useContext(ClientesContext);
    const [produtoDetalhado, setProdutoDetalhado] = useState(null);
    const [paginacao, setPaginacao] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [numPage, setNumPage] = useState([]);
    const [telaErro, setTelaErro] = useState(<div></div>);
    const [qtdZero, setQtdZero] = useState(false);
    const navigation = useNavigate();
    const classes = useStyles();
    const [showPagination, setShowPagination] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isFavorite, setIsFavorite] = useState(false);
    const [showMessage, setShowMessage] = useState();
    const [search, setSearch] = useState(false);

    const isMobilePage = width <= 768;

    const theme = createTheme({
        palette: {
            clientes: {
                main: "#ddd",
                light: "#a6a167",
                dark: "#484739",
                contrastText: "#fff",
            },
        },
    });

    useEffect(() => {
        axios.all([
            HTTP.get(`${process.env.REACT_APP_BASE_HTTP}/produtos`, {
                headers: { authorization: "Bearer " + token },
            })
                .then((response) => {
                    if (response.data.length > 0) {
                        response.data.forEach((target) => {
                            target.quantidade = 0;
                        });
                    }
                    let result = storePaginacao(response.data);
                    storeProdutos(response.data);
                    checkNumPage(result);
                    checkPagination(result);
                    setPaginacao(result);
                    console.log(result);
                })
                .catch((error) => {
                    if (error.response.data.message == "Não autorizado") {
                        console.log("token expirado");
                        setTelaErro(<TelaRetorno />);
                    }
                }),
        ]);
    }, []);

    function checkNumPage(array) {
        let element = [];
        for (let i = 0; i < array.length; i++) {
            element.push({
                pagina: i + 1,
                isActive: i == 0 ? true : false,
            });
        }
        setNumPage(element);
    }

    function checkPagination(array) {
        if (array.length > 4) {
            setShowPagination(true);
        } else {
            setShowPagination(false);
        }
    }

    useEffect(() => {
        if (produtosFiltrados) {
            let result = storePaginacao(produtosFiltrados);
            setShowPagination(true);
            checkPagination(result);
            checkNumPage(result);
            setPaginacao(result);
        } else {
            let result = storePaginacao(produtos);
            checkPagination(result);
            checkNumPage(result);
            setPaginacao(result);
        }
    }, [produtosFiltrados]);

    useEffect(() => {
        if (filtroTexto) {
            let result = storePaginacao(filtroTexto);
            checkPagination(result);
            checkNumPage(result);
            setPaginacao(result);
        } else {
            let result = storePaginacao(produtos);
            checkPagination(result);
            checkNumPage(result);
            setPaginacao(result);
        }
    }, [filtroTexto]);

    useEffect(() => {
        if (chosenProduct != null) {
            axios.all([
                HTTP.get(`${process.env.REACT_APP_BASE_HTTP}/produtos/detalhados`, {
                    headers: { authorization: "Bearer " + token },
                    params: {
                        codigo_de_barras: chosenProduct.codigo_de_barras,
                        id_estabelecimento: chosenProduct.id_estabelecimento,
                    },
                }).then((response) => {
                    let produto = response.data;
                    produto.quantidade = chosenProduct.quantidade;
                    setProdutoDetalhado(produto);
                }),
            ]);
        } else {
            setProdutoDetalhado(null);
        }
    }, [chosenProduct]);

    function handleQuantidade(item, op) {
        let array = [...paginacao];
        array.forEach((element) => {
            element.forEach((a) => {
                if (a === item) {
                    if (op == "minus") {
                        if (a.quantidade !== 0) a.quantidade = a.quantidade - a.quantidade_minima;
                    } else if (op == "plus") {
                        a.quantidade = a.quantidade + a.quantidade_minima;
                    } else {
                        a.quantidade = +op;
                    }
                }
            });
        });
        setPaginacao(array);
    }

    function handleQuantidadeChosen(op) {
        let produto = JSON.parse(JSON.stringify(produtoDetalhado));
        if (op == "minus") {
            if (produto.quantidade <= 0) {
                produto.quantidade = 0;
            } else {
                produto.quantidade--;
            }
        } else if (op == "plus") {
            produto.quantidade++;
        } else {
            produto.quantidade = +op;
        }
        setProdutoDetalhado(produto);
    }

    function adcCarrinho(produto) {
        if (produto.quantidade == 0) {
            setQtdZero(true);
        } else {
            setQtdZero(false);
            storeCarrinho(produto);
            setProdutoDetalhado(null);
            selectProduct(null);
            setShowHeader(true);
        }
    }

    function adcCarrinhoMobile(produto) {
        produto.quantidade = 1;
        // setQtdZero(false);
        storeCarrinho(produto);
        setProdutoDetalhado(null);
        selectProduct(null);
        setShowHeader(false);
    }

    // function handleSubCard() {
    //     console.log("subcards");
    // }

    function selectNumPage(element) {
        const result = numPage.map((target) => {
            target.isActive = false;
            if (element.pagina == target.pagina) target.isActive = true;
            return target;
        });
        setPaginaAtual(element.pagina);
        setNumPage(result);
    }

    function moneyMask(price) {
        let value = price.toFixed(2);

        value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
        let result = (+value / 100).toFixed(2);
        result = result < 0 || value === "" || result == null ? 0.0 : result;
        result = result.replace(".", ",");

        return "R$ " + result;
    }

    function sumCalc() {
        let result = 0;
        return (result += produtoDetalhado.preco_venda * produtoDetalhado.quantidade);
    }

    useEffect(() => {}, []);

    if (device != "mobile") {
        return (
            <ContainerGeral>
                {telaErro}
                {produtoDetalhado === null ? (
                    <div>
                        <TituloPagina>{props.tituloPagina}</TituloPagina>
                        {paginacao.length > 0 ? (
                            <div>
                                <ContainerCards>
                                    {paginacao.length > 0 &&
                                        paginacao[paginaAtual - 1].map((element, index) => (
                                            <CardProduto
                                                key={index}
                                                produto={element}
                                                handleCard={() => selectProduct(element)}
                                                handleQuantidade={handleQuantidade}
                                                storeCarrinho={() => adcCarrinho(element)}
                                                qtdZero={qtdZero}
                                            />
                                        ))}
                                </ContainerCards>
                                <ContainerPagination>
                                    <ContainerButtonsPagination>
                                        <div>
                                            {showPagination && (
                                                <ThemeProvider theme={theme}>
                                                    <Pagination
                                                        count={paginacao.length}
                                                        shape="rounded"
                                                        onChange={(event, value) => setPaginaAtual(value)}
                                                        color="clientes"
                                                        className={classes.root}
                                                    />
                                                </ThemeProvider>
                                            )}
                                            {!showPagination && (
                                                <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                                    <ContainerPrevNext
                                                        style={{
                                                            paddingRight: "0.5vw",
                                                            borderRight: "0.5px solid var(--background-clientes-claro)",
                                                            borderTopLeftRadius: "10px",
                                                            borderBottomLeftRadius: "10px",
                                                        }}
                                                        onClick={() =>
                                                            setPaginaAtual(paginaAtual - 1 < 1 ? 1 : paginaAtual - 1)
                                                        }
                                                    >
                                                        <GrFormPrevious size={20} color={"#000"} />
                                                        Anterior
                                                    </ContainerPrevNext>
                                                    {numPage.map((item, index) => (
                                                        <BotaoPagination
                                                            key={item.pagina}
                                                            onClick={() => selectNumPage(item)}
                                                            style={{
                                                                borderWidth: item.isActive == true ? "1px" : "",
                                                                borderStyle: item.isActive == true ? "solid" : "",
                                                                borderColor:
                                                                    item.isActive == true
                                                                        ? "var(--background-clientes-escuro)"
                                                                        : "",
                                                            }}
                                                        >
                                                            {item.pagina}
                                                        </BotaoPagination>
                                                    ))}
                                                    <ContainerPrevNext
                                                        style={{
                                                            paddingLeft: "0.5vw",
                                                            borderLeft: "0.5px solid var(--background-clientes-claro)",
                                                            borderTopRightRadius: "10px",
                                                            borderBottomRightRadius: "10px",
                                                        }}
                                                        onClick={() =>
                                                            setPaginaAtual(
                                                                paginaAtual + 1 > paginacao.length
                                                                    ? paginacao.length
                                                                    : paginaAtual + 1
                                                            )
                                                        }
                                                    >
                                                        Próximo
                                                        <GrFormNext size={20} color={"red"} />
                                                    </ContainerPrevNext>
                                                </div>
                                            )}
                                        </div>
                                    </ContainerButtonsPagination>
                                </ContainerPagination>
                            </div>
                        ) : (
                            <div>
                                <h1 style={{ color: "#ef6969" }}>Não há itens nesse filtro</h1>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <TextoBtnTitulo onClick={() => [selectProduct(null), setProdutoDetalhado(null)]}>
                                {"Produtos "}
                            </TextoBtnTitulo>
                            <TituloPagina>&nbsp;{">"}&nbsp;</TituloPagina>
                            <TextoBtnTitulo
                                onClick={() => [
                                    changeFiltro("categoria_2", produtoDetalhado.categoria_2),
                                    selectProduct(null),
                                    setProdutoDetalhado(null),
                                ]}
                            >
                                {" " + produtoDetalhado.categoria_2}
                            </TextoBtnTitulo>
                            <TituloPagina>&nbsp;{"> " + produtoDetalhado.descricao}</TituloPagina>
                        </div>
                        <ContainerProduto>
                            <ContainerClose>
                                <IoCloseCircleOutline
                                    style={{ cursor: "pointer" }}
                                    size={35}
                                    color={"#999999"}
                                    onClick={() => [selectProduct(null), setProdutoDetalhado(null)]}
                                />
                            </ContainerClose>
                            <ImagemProduto
                                src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${produtoDetalhado.codigo_de_barras}.webp`}
                                style={{ maxWidth: userDimensions.width * 0.15 }}
                            />
                            <ContainerTexto>
                                <TituloProduto>{produtoDetalhado.descricao}</TituloProduto>
                                <InformacoesProduto>EAN {produtoDetalhado.codigo_de_barras}</InformacoesProduto>
                                <InformacoesProduto>{produtoDetalhado.descricao_detalhada}</InformacoesProduto>
                                <InformacoesProduto>{produtoDetalhado.marca}</InformacoesProduto>
                                <InformacoesProduto>Enviado por {produtoDetalhado.marca}</InformacoesProduto>
                                <InformacoesProduto>
                                    Envio em {produtoDetalhado.prazo_entrega} dia(s)
                                </InformacoesProduto>
                                <InformacoesProduto>Pedido mínimo: 1</InformacoesProduto>
                            </ContainerTexto>
                            <ContainerPrecos>
                                <PrecoPrincipal>{moneyMask(produtoDetalhado.preco_venda)}</PrecoPrincipal>
                                <div
                                    style={{
                                        width: "auto",
                                        padding: "2px 4px",
                                        display: "inline-block",
                                        margin: 0,
                                        borderRadius: 10,
                                    }}
                                >
                                    <InformacoesPreco style={{ backgroundColor: "#F5F5F5" }}>
                                        {moneyMask(produtoDetalhado.preco_unitario)}/{produtoDetalhado.unidade_medida}
                                    </InformacoesPreco>
                                </div>
                                <PrecoPrincipal style={{ fontWeight: "700" }}>
                                    Subtotal: {moneyMask(sumCalc())}
                                </PrecoPrincipal>
                                <BtnQtd>
                                    <BtnMinus
                                        onClick={() => {
                                            handleQuantidadeChosen("minus");
                                        }}
                                    >
                                        <IconMinus size={12} color={"#000"} style={{ zIndex: 1 }} />
                                    </BtnMinus>
                                    <InputQtd
                                        type="number"
                                        value={produtoDetalhado.quantidade}
                                        onChange={(text) => {
                                            handleQuantidadeChosen(text.target.value);
                                        }}
                                    />
                                    <BtnPlus
                                        onClick={() => {
                                            handleQuantidadeChosen("plus");
                                        }}
                                    >
                                        <IconPlus size={12} color={"#000"} />
                                    </BtnPlus>
                                </BtnQtd>
                                {qtdZero && <MsgError mensagem="A quantidade não pode ser 0!" />}
                                <BtnAdc onClick={() => adcCarrinho(produtoDetalhado)}>Adicionar</BtnAdc>
                            </ContainerPrecos>
                        </ContainerProduto>
                        <ContainerRelacionados>
                            {/* <TituloPagina>{"Produtos Relacionados"}</TituloPagina> */}
                            {/* <CardProduto key={index} produto={element} handleCard={() => selectProduct(element)} />; */}
                        </ContainerRelacionados>
                    </div>
                )}
            </ContainerGeral>
        );
    }
    if (device == "mobile") {
        return (
            <ContainerGeralMobile>
                {telaErro}
                {produtoDetalhado === null ? (
                    <div style={{ height: "100%" }}>
                        <TituloPagina style={{ fontSize: "1rem", paddingLeft: "10px" }}>
                            {props.tituloPagina}
                        </TituloPagina>
                        {paginacao.length > 0 ? (
                            <ContainerMainMobile>
                                {/* <div>
                                    <SubCardsProdutos
                                    //  firstNames={[produtoDetalhado.descricao]}
                                    // onClick={handleSubCard}
                                    />
                                </div> */}
                                <ContainerCardsMobile>
                                    {paginacao.length > 0 &&
                                        paginacao[paginaAtual - 1].map((element, index) => (
                                            <CardProduto
                                                key={index}
                                                onClick={() => [selectProductEnter(true, element)]}
                                                produto={element}
                                                handleCard={() => selectProduct(element)}
                                                storeCarrinho={() => [
                                                    adcCarrinhoMobile(element),
                                                    selectProduct(element),
                                                ]}

                                                // storeCarrinhoMobile={() => [
                                                //     selectProduct(element),
                                                //     adcCarrinhoMobile(element),
                                                // ]}
                                                // qtdZero={qtdZero}
                                            />
                                        ))}
                                </ContainerCardsMobile>
                                <ContainerPagination>
                                    <ContainerButtonsPagination>
                                        <ButtonsPagination>
                                            {showPagination && (
                                                <ThemeProvider theme={theme}>
                                                    <Pagination
                                                        count={paginacao.length}
                                                        shape="rounded"
                                                        onChange={(event, value) => setPaginaAtual(value)}
                                                        color="clientes"
                                                        className={classes.root}
                                                        hidePrevButton
                                                        hideNextButton
                                                    />
                                                </ThemeProvider>
                                            )}
                                            {!showPagination && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        maxWidth: "100vw",
                                                        gap: 5,
                                                    }}
                                                >
                                                    <ContainerPrevNext
                                                        style={{
                                                            paddingRight: "0.5vw",
                                                            borderRight: "0.5px solid var(--background-clientes-claro)",
                                                            borderTopLeftRadius: "10px",
                                                            borderBottomLeftRadius: "10px",
                                                        }}
                                                        onClick={() =>
                                                            setPaginaAtual(paginaAtual - 1 < 1 ? 1 : paginaAtual - 1)
                                                        }
                                                    >
                                                        <GrFormPrevious size={20} color={"#000"} />
                                                    </ContainerPrevNext>
                                                    {numPage.map((item, index) => (
                                                        <BotaoPagination
                                                            key={item.pagina}
                                                            onClick={() => selectNumPage(item)}
                                                            style={{
                                                                height: "100%",
                                                                borderWidth: item.isActive == true ? "1px" : "",
                                                                borderStyle: item.isActive == true ? "solid" : "",
                                                                borderColor:
                                                                    item.isActive == true
                                                                        ? "var(--background-clientes-escuro)"
                                                                        : "",
                                                            }}
                                                        >
                                                            {item.pagina}
                                                        </BotaoPagination>
                                                    ))}
                                                    <ContainerPrevNext
                                                        style={{
                                                            paddingLeft: "0.5vw",
                                                            borderLeft: "0.5px solid var(--background-clientes-claro)",
                                                            borderTopRightRadius: "10px",
                                                            borderBottomRightRadius: "10px",
                                                        }}
                                                        onClick={() =>
                                                            setPaginaAtual(
                                                                paginaAtual + 1 > paginacao.length
                                                                    ? paginacao.length
                                                                    : paginaAtual + 1
                                                            )
                                                        }
                                                    >
                                                        <GrFormNext size={20} color={"#000"} />
                                                    </ContainerPrevNext>
                                                </div>
                                            )}
                                        </ButtonsPagination>
                                    </ContainerButtonsPagination>
                                </ContainerPagination>
                            </ContainerMainMobile>
                        ) : (
                            <div>
                                <h1 style={{ color: "#ef6969", fontSize: "18px", textAlign: "center" }}>
                                    Não há itens nesse filtro
                                </h1>

                                <DivDuvidas name="duvidas">
                                    <summary style={{ fontFamily: "Poppins", color: "#222", marginBottom: "10px" }}>
                                        Não encontrou o produto que procurava?
                                    </summary>
                                    <p style={{ fontFamily: "Poppins" }}>
                                        Verifique <span style={{ color: "#ef6969" }}>espaços</span> ou{" "}
                                        <span style={{ color: "#ef6969" }}>erros</span> de digitação em sua busca.
                                    </p>
                                </DivDuvidas>
                                <DivDuvidas name="duvidas">
                                    <summary style={{ fontFamily: "Poppins", color: "#222", marginBottom: "10px" }}>
                                        Digitou corretamente mas o produto não apareceu?
                                    </summary>
                                    <p style={{ fontFamily: "Poppins" }}>
                                        Fale com seu representante comercial.
                                        <BtnContato href="https://api.whatsapp.com/send?phone=5544998483218&text=Olá,%20estava%20no%20site%20e%20não%20encontrei%20o%20que%20procurava!">
                                            Whatsapp
                                        </BtnContato>
                                    </p>
                                </DivDuvidas>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "60px",
                            }}
                        >
                            <HiMiniArrowLeft
                                fill="#6F6C54"
                                style={{ position: "absolute" }}
                                onClick={() => [
                                    changeFiltro("categoria_2", produtoDetalhado.categoria_2),
                                    selectProduct(null),
                                    setProdutoDetalhado(null),
                                    selectProduct(null),
                                    setProdutoDetalhado(null),
                                    setShowHeader(true),
                                ]}
                            />
                            <img
                                src={logoAlimenzMobile}
                                alt="Logo alimenz mobile"
                                style={{ width: "25%", maxWidth: "150px", display: "flex", margin: "0 auto" }}
                            />
                        </div>

                        {/* MAXWIDTH-768 */}
                        {isMobilePage ? (
                            <ContainerProdutoMobile>
                                <span>
                                    <button onClick={() => handleFavorito(produtoDetalhado)}>
                                        {isFavorite ? (
                                            <img src={starYellow} />
                                        ) : (
                                            <IoIosStarOutline fill="#333" size={35} />
                                        )}
                                    </button>
                                </span>
                                <ContainerImage>
                                    <ImagemProduto
                                        src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${produtoDetalhado.codigo_de_barras}.webp`}
                                        style={{ maxWidth: userDimensions.width * 0.15 }}
                                    />
                                </ContainerImage>
                                <ContainerTextosMobile>
                                    <SubCtnTextos>
                                        <TituloProduto>{produtoDetalhado.descricao}</TituloProduto>
                                        <div>
                                            <InformacoesProduto>
                                                EAN {produtoDetalhado.codigo_de_barras}
                                            </InformacoesProduto>
                                            <PrecoPrincipal style={{ marginTop: "15px" }}>
                                                {moneyMask(produtoDetalhado.preco_venda)}
                                            </PrecoPrincipal>
                                        </div>
                                        <div>
                                            <ButtonsMobile>
                                                <BtnQtd>
                                                    <BtnMinus
                                                        onClick={() => {
                                                            handleQuantidadeChosen("minus");
                                                        }}
                                                    >
                                                        <IconMinus />
                                                    </BtnMinus>
                                                    <InputQtd
                                                        type="number"
                                                        value={produtoDetalhado.quantidade}
                                                        onChange={(text) => {
                                                            handleQuantidadeChosen(text.target.value);
                                                        }}
                                                    />
                                                    <BtnPlus
                                                        onClick={() => {
                                                            handleQuantidadeChosen("plus");
                                                        }}
                                                    >
                                                        <IconPlus size={12} color={"#000"} />
                                                    </BtnPlus>
                                                </BtnQtd>
                                                <BtnAdcMobile onClick={() => adcCarrinho(produtoDetalhado)}>
                                                    Adicionar
                                                    <PrecoPrincipal
                                                        style={{
                                                            fontWeight: "700",
                                                            fontSize: "0.8rem",
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        {moneyMask(sumCalc())}
                                                    </PrecoPrincipal>
                                                </BtnAdcMobile>
                                            </ButtonsMobile>
                                            {qtdZero && <MsgError mensagem="A quantidade não pode ser 0!" />}
                                        </div>
                                    </SubCtnTextos>
                                    <div>
                                        <InformacoesProduto style={{ paddingTop: "20px" }}>
                                            Envio em {produtoDetalhado.prazo_entrega} dia(s)
                                        </InformacoesProduto>
                                        <h3
                                            style={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                color: "#000000BD",
                                            }}
                                        >
                                            Descrição do produto
                                        </h3>
                                        <div
                                            style={{
                                                height: "130px",
                                                overflow: "scroll",
                                                paddingBottom: "10px",
                                            }}
                                        >
                                            <InformacoesProduto>
                                                {produtoDetalhado.descricao_detalhada}
                                            </InformacoesProduto>
                                        </div>
                                    </div>
                                </ContainerTextosMobile>
                            </ContainerProdutoMobile>
                        ) : (
                            <ContainerProduto>
                                <ContainerClose>
                                    <IoCloseCircleOutline
                                        style={{ cursor: "pointer" }}
                                        size={35}
                                        color={"#999999"}
                                        onClick={() => [selectProduct(null), setProdutoDetalhado(null)]}
                                    />
                                </ContainerClose>
                                <ImagemProduto
                                    src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${produtoDetalhado.codigo_de_barras}.webp`}
                                    style={{ maxWidth: userDimensions.width * 0.15 }}
                                />
                                <ContainerTexto>
                                    <TituloProduto>{produtoDetalhado.descricao}</TituloProduto>
                                    <InformacoesProduto>EAN {produtoDetalhado.codigo_de_barras}</InformacoesProduto>
                                    <InformacoesProduto>{produtoDetalhado.descricao_detalhada}</InformacoesProduto>
                                    <InformacoesProduto>{produtoDetalhado.marca}</InformacoesProduto>
                                    <InformacoesProduto>Enviado por {produtoDetalhado.marca}</InformacoesProduto>
                                    <InformacoesProduto>
                                        Envio em {produtoDetalhado.prazo_entrega} dia(s)
                                    </InformacoesProduto>
                                    <InformacoesProduto>Pedido mínimo: 1</InformacoesProduto>
                                </ContainerTexto>
                                <ContainerPrecos>
                                    <PrecoPrincipal>{moneyMask(produtoDetalhado.preco_venda)}</PrecoPrincipal>
                                    <div
                                        style={{
                                            width: "auto",
                                            padding: "2px 4px",
                                            display: "inline-block",
                                            margin: 0,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <InformacoesPreco style={{ backgroundColor: "#F5F5F5" }}>
                                            {moneyMask(produtoDetalhado.preco_unitario)}/
                                            {produtoDetalhado.unidade_medida}
                                        </InformacoesPreco>
                                    </div>
                                    <PrecoPrincipal style={{ fontWeight: "700" }}>
                                        Subtotal: {moneyMask(sumCalc())}
                                    </PrecoPrincipal>
                                    <BtnQtd>
                                        <BtnMinus
                                            onClick={() => {
                                                handleQuantidadeChosen("minus");
                                            }}
                                        >
                                            <IconMinus size={12} color={"#000"} style={{ zIndex: 1 }} />
                                        </BtnMinus>
                                        <InputQtd
                                            type="number"
                                            value={produtoDetalhado.quantidade}
                                            onChange={(text) => {
                                                handleQuantidadeChosen(text.target.value);
                                            }}
                                        />
                                        <BtnPlus
                                            onClick={() => {
                                                handleQuantidadeChosen("plus");
                                            }}
                                        >
                                            <IconPlus size={12} color={"#000"} />
                                        </BtnPlus>
                                    </BtnQtd>
                                    {/* {qtdZero && <MsgError mensagem="A quantidade não pode ser 0!" />}
                                     */}
                                    <BtnAdc onClick={() => adcCarrinho(produtoDetalhado)}>Adicionar</BtnAdc>
                                </ContainerPrecos>
                            </ContainerProduto>
                        )}

                        <ContainerRelacionados>
                            {/* <TituloPagina>{"Produtos Relacionados"}</TituloPagina> */}
                            {/* <CardProduto key={index} produto={element} handleCard={() => selectProduct(element)} />; */}
                        </ContainerRelacionados>
                    </div>
                )}
            </ContainerGeralMobile>
        );
    } else {
        return (
            <div>
                <p>Você não está autenticado. Redirecionando para a página de login...</p>
            </div>
        );
    }
}
