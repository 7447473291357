import React, { useContext, useState, useEffect } from "react";
import { ClientesContext } from "../../contexts/clientes";
import { AuthContext } from "../../contexts/auth";
import HeaderClientes from "../../components/HeaderClientes";
import {
    ContainerGeral,
    TituloPagina,
    ContainerCarrinho,
    ContainerPedidos,
    ContainerResumo,
    CardResumo,
    TitleResumo,
    DescricaoResumo,
    BtnResumo,
    TextoResumo,
    TotalResumo,
    ContainerDescricaoResumo,
    ContainerBtn,
} from "./styles";
import CardCarrinho from "../../components/CardCarrinho";
import MsgStatus from "../../components/MsgStatus";
import { FaRegCircleCheck } from "react-icons/fa6";
import axios from "axios";
import HTTP from "../../http/http";
import { useNavigate } from "react-router-dom";
import MenuFooterMobile from "../../components/MenuFooterMobile";
import HeaderComponentMobile from "../../components/HeaderComponentMobile";

export default function Clientes() {
    const { carrinho, setCarrinho, createListaCarrinho, limpaCarrinho, storeCarrinho } = useContext(ClientesContext);
    const { token, user, device } = useContext(AuthContext);
    const [listaCarrinho, setListaCarrinho] = useState([]);
    const navigation = useNavigate();
    const [isSending, setIsSending] = useState(false);
    const [showStatus, setShowStatus] = useState({ validate: false, msg1: "", msg2: "", status: null });

    useEffect(() => {
        if (carrinho.length > 0) {
            setListaCarrinho(createListaCarrinho(carrinho));
        }
    }, [carrinho]);

    function handleQuantidade(item, op) {
        let array = [...carrinho];
        array.forEach((a) => {
            if (a === item) {
                if (op == "minus") {
                    a.quantidade--;
                } else if (op == "plus") {
                    a.quantidade++;
                } else {
                    a.quantidade = +op;
                }
            }
        });
        setListaCarrinho(createListaCarrinho(array));
    }

    function sumCalc(brand) {
        let result = 0;

        Object.keys(listaCarrinho).forEach((element) => {
            listaCarrinho[element].map((target) => {
                if (brand == target.nome) {
                    result += target.preco_venda * target.quantidade;
                }
            });
        });

        if (result < listaCarrinho[brand][0].pedido_frete_gratis) result = result + listaCarrinho[brand][0].valor_frete;

        return result;
    }

    function sumTotal() {
        let calc = 0;

        Object.keys(listaCarrinho).map((element, i) => {
            calc += sumCalc(Object.keys(listaCarrinho)[i]);
        });

        return calc;
    }

    function moneyMask(price) {
        let value = price.toFixed(2);
        value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
        let result = (+value / 100).toFixed(2);
        result = result < 0 || value === "" || result == null ? 0.0 : result;
        result = result.replace(".", ",");
        return "R$ " + result;
    }

    function confirmaPedido() {
        setIsSending(true);
        let data = {
            id_usuario: user.id_usuario,
            id_estabelecimento: user.id_estabelecimento,
            estabelecimentos: [],
        };
        let arrayObj = [];

        carrinho.forEach((element) => {
            const obj = {};
            obj.sku = element.sku;
            obj.descricao = element.descricao;
            obj.quantidade = element.quantidade;
            obj.valor = element.preco_venda;
            obj.id_estabelecimento = element.id_estabelecimento;
            obj.unidade_medida = element.unidade_medida;
            arrayObj.push(obj);
        });

        arrayObj = Object.groupBy(arrayObj, ({ id_estabelecimento }) => id_estabelecimento);

        let estabelecimentos = [];

        Object.values(arrayObj).forEach((element) => {
            let data = {
                id_estabelecimento: element[0].id_estabelecimento,
                itens: element,
            };
            estabelecimentos.push(data);
        });

        data.estabelecimentos = estabelecimentos;
        console.log("pedido enviado");

        HTTP.post(`${process.env.REACT_APP_BASE_HTTP}/pedidos`, data, { headers: { authorization: "Bearer " + token } })
            .then((response) => {
                limpaCarrinho();
                setShowStatus({
                    validate: true,
                    msg1: "Pedido criado com sucesso!",
                    msg2: "Você poderá acompanhá-lo na tela de pedidos.",
                    status: true,
                });
                setTimeout(() => {
                    setShowStatus({
                        validate: false,
                        msg1: "",
                        msg2: "",
                        status: null,
                    });
                    setIsSending(false);
                    navigation("/carrinho-clientes");
                }, 3000);
            })
            .catch((err) => {
                setShowStatus({
                    validate: true,
                    msg1: "Erro ao finalizar o pedido!",
                    msg2: "Por favor, entre em contato com nosso suporte.",
                    status: false,
                });
                console.log(err);
                setTimeout(() => {
                    setShowStatus({
                        validate: false,
                        msg1: "",
                        msg2: "",
                        status: null,
                    });
                    setIsSending(false);
                }, 3000);
            });
    }

    if (device != "mobile") {
        return (
            <div>
                <HeaderClientes />
                <TituloPagina>{"Carrinho > Revisão de Compra"}</TituloPagina>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {showStatus.validate && (
                        <MsgStatus
                            mensagem1={showStatus.msg1}
                            mensagem2={showStatus.msg2}
                            isPositive={showStatus.status}
                        />
                    )}
                    {carrinho.length > 0 ? (
                        <ContainerGeral>
                            <ContainerCarrinho>
                                <ContainerPedidos>
                                    {Object.keys(listaCarrinho).map((element, i) => {
                                        return (
                                            <CardCarrinho
                                                handleQuantidade={handleQuantidade}
                                                pedidos={listaCarrinho[element]}
                                                key={i}
                                            />
                                        );
                                    })}
                                </ContainerPedidos>
                            </ContainerCarrinho>
                            <ContainerResumo>
                                <CardResumo>
                                    <TitleResumo>Resumo do Pedido</TitleResumo>
                                    {Object.keys(listaCarrinho).map((element, i) => {
                                        return (
                                            <ContainerDescricaoResumo key={i}>
                                                <DescricaoResumo
                                                    style={{
                                                        maxWidth: "26ch",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    Fornecedor {Object.keys(listaCarrinho)[i]}
                                                </DescricaoResumo>
                                                <DescricaoResumo>
                                                    {moneyMask(sumCalc(Object.keys(listaCarrinho)[i]))}
                                                </DescricaoResumo>
                                                <FaRegCircleCheck
                                                    style={{ position: "absolute", right: 0, padding: 0 }}
                                                    size={15}
                                                    color={"#378B19"}
                                                />
                                            </ContainerDescricaoResumo>
                                        );
                                    })}
                                    <ContainerDescricaoResumo>
                                        <TitleResumo>Total do Pedido</TitleResumo>
                                        <TitleResumo>{moneyMask(sumTotal())}</TitleResumo>
                                    </ContainerDescricaoResumo>
                                    <ContainerBtn>
                                        <BtnResumo
                                            onClick={() => {
                                                if (!isSending) confirmaPedido();
                                            }}
                                            style={{ borderColor: "#206109", background: "#378B19" }}
                                        >
                                            <TextoResumo>CONFIRMAR</TextoResumo>
                                        </BtnResumo>
                                    </ContainerBtn>
                                </CardResumo>
                            </ContainerResumo>
                        </ContainerGeral>
                    ) : (
                        <ContainerGeral>
                            <h1 style={{ color: "#ef6969" }}>Não há itens no carrinho</h1>
                        </ContainerGeral>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <>
                <HeaderComponentMobile title="CARRINHO" />
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {showStatus.validate && (
                        <MsgStatus
                            mensagem1={showStatus.msg1}
                            mensagem2={showStatus.msg2}
                            isPositive={showStatus.status}
                        />
                    )}
                    {carrinho.length > 0 ? (
                        <ContainerGeral>
                            <ContainerCarrinho>
                                <ContainerPedidos>
                                    {Object.keys(listaCarrinho).map((element, i) => {
                                        return (
                                            <CardCarrinho
                                                handleQuantidade={handleQuantidade}
                                                pedidos={listaCarrinho[element]}
                                                key={i}
                                            />
                                        );
                                    })}
                                </ContainerPedidos>
                            </ContainerCarrinho>
                            <ContainerResumo>
                                <CardResumo style={{ boxShadow: "none", borderRadius: "0px" }}>
                                    <TitleResumo>Resumo do Pedido</TitleResumo>
                                    {Object.keys(listaCarrinho).map((element, i) => {
                                        return (
                                            <ContainerDescricaoResumo key={i}>
                                                <DescricaoResumo
                                                    style={{
                                                        maxWidth: "26ch",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    <p style={{ fontSize: "13px", fontFamily: "Roboto" }}>
                                                        {Object.keys(listaCarrinho)[i]}
                                                    </p>
                                                </DescricaoResumo>
                                                <DescricaoResumo style={{ fontSize: "12px" }}>
                                                    {moneyMask(sumCalc(Object.keys(listaCarrinho)[i]))}
                                                </DescricaoResumo>
                                                <FaRegCircleCheck
                                                    style={{ position: "absolute", right: 0, padding: 0 }}
                                                    size={15}
                                                    color={"#378B19"}
                                                />
                                            </ContainerDescricaoResumo>
                                        );
                                    })}
                                    <ContainerDescricaoResumo>
                                        <TotalResumo>Total do Pedido</TotalResumo>
                                        <TotalResumo>{moneyMask(sumTotal())}</TotalResumo>
                                    </ContainerDescricaoResumo>
                                    <ContainerBtn>
                                        <BtnResumo
                                            onClick={() => {
                                                if (!isSending) confirmaPedido();
                                            }}
                                        >
                                            <TextoResumo>CONFIRMAR</TextoResumo>
                                        </BtnResumo>
                                    </ContainerBtn>
                                </CardResumo>
                            </ContainerResumo>
                        </ContainerGeral>
                    ) : (
                        <ContainerGeral>
                            <h1 style={{ color: "#ef6969", fontSize: "16px", textAlign: "center" }}>
                                Não há itens no carrinho.
                            </h1>
                        </ContainerGeral>
                    )}
                </div>
                <MenuFooterMobile />
            </>
        );
    }
}
