import React, { useState, useEffect, useContext } from "react";
import {
    ContainerMobile,
    Imagem,
    ContainerImageUser,
    ContainerImage,
    ButtonHelp,
    ContainerTexts,
    ContainerHelper,
    TextOut,
} from "./styles";
import logo from "../../assets/img/logo.png";
import logoWritten from "../../assets/img/logo.png";
import { FaShoppingCart } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { MdLogout } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa6";
import { AuthContext } from "../../contexts/auth";
import { FiXCircle } from "react-icons/fi";
import consultorImage from "../../assets/img/consultor-alimenz.png";
import { FaWhatsapp } from "react-icons/fa";
import { ContainerCount, BtnHeader } from "../HeaderClientes/styles";
import { ClientesContext } from "../../contexts/clientes";
import { FiHeart } from "react-icons/fi";
import shoppingCart from "../../assets/img/shoppingcart.png";

export default function HeaderClientesMobile({ clearFilter }) {
    const { carrinho } = useContext(ClientesContext);
    const { logoutApp } = React.useContext(AuthContext);
    const navigation = useNavigate();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const width = window.innerWidth;
    const [showCount, setShowCount] = useState(false);

    useEffect(() => {
        if (carrinho.length > 0) setShowCount(true);
    }, [carrinho]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    function checkLocation(value) {
        return location.pathname == value ? true : false;
    }

    function logout() {
        logoutApp();
        navigation("/");
    }
    const DrawerList = (
        <Box sx={{ width: width * 0.65 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        height: "40px",
                    }}
                >
                    <Imagem
                        style={{ display: "flex", maxWidth: "120px", height: "25px" }}
                        onClick={() => {
                            navigation("/produtos-clientes");
                        }}
                        src={logoWritten}
                    />
                    <FiXCircle
                        onClick={() => {
                            toggleDrawer(false);
                        }}
                        color={"var(--background-clientes-escuro)"}
                        size={20}
                        style={{ position: "absolute", right: "5px" }}
                    />
                </div>
                <ListItem disablePadding>
                    <ListItemButton
                        style={{
                            backgroundColor: checkLocation("/produtos-clientes")
                                ? "var(--background-clientes-escuro"
                                : "#fff",
                        }}
                        onClick={() => {
                            navigation("/produtos-clientes");
                        }}
                    >
                        <ListItemIcon>
                            <FaSearch
                                color={
                                    checkLocation("/produtos-clientes") ? "#fff" : "var(--background-clientes-escuro)"
                                }
                                size={22}
                                style={{ padding: 0 }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Produtos"}
                            style={{
                                color: checkLocation("/produtos-clientes")
                                    ? "#fff"
                                    : "var(--background-clientes-escuro)",
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        style={{
                            borderBottom: "1px solid #d9d9d9",
                            backgroundColor: checkLocation("/carrinho-clientes")
                                ? "var(--background-clientes-escuro)"
                                : "#fff",
                        }}
                        onClick={() => {
                            navigation("/carrinho-clientes");
                        }}
                    >
                        <ListItemIcon>
                            <img src={shoppingCart} alt="shopping-cart" width={25} />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Carrinho"}
                            style={{
                                color: checkLocation("/carrinho-clientes")
                                    ? "#fff"
                                    : "var(--background-clientes-escuro)",
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        style={{
                            borderBottom: "1px solid #d9d9d9",
                            backgroundColor: checkLocation("/carrinho-clientes")
                                ? "var(--background-clientes-escuro)"
                                : "#fff",
                        }}
                        onClick={() => {
                            navigation("/pedidos-favoritos");
                        }}
                    >
                        <ListItemIcon>
                            <FiHeart
                                color={
                                    checkLocation("/carrinho-clientes") ? "#fff" : "var(--background-clientes-escuro)"
                                }
                                size={25}
                                style={{ padding: 0 }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Favoritos"}
                            style={{
                                color: checkLocation("/carrinho-clientes")
                                    ? "#fff"
                                    : "var(--background-clientes-escuro)",
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        style={{
                            borderBottom: "1px solid #d9d9d9",
                            backgroundColor: checkLocation("/pedidos-clientes")
                                ? "var(--background-clientes-escuro)"
                                : "#fff",
                        }}
                        onClick={() => {
                            navigation("/pedidos-clientes");
                        }}
                    >
                        <ListItemIcon>
                            <FaClipboardCheck
                                color={checkLocation("/pedidos-clientes") ? "#fff" : "#3337"}
                                size={25}
                                style={{ padding: 0 }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Meus Pedidos"}
                            style={{
                                color: checkLocation("/pedidos-clientes")
                                    ? "#fff"
                                    : "var(--background-clientes-escuro)",
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
            <List style={{ position: "absolute", bottom: "0px", padding: "0", width: "100%" }}>
                <ContainerHelper>
                    <ContainerImageUser>
                        <ContainerImage>
                            <img src={consultorImage} alt="" />
                        </ContainerImage>
                        <ContainerTexts>
                            {/* <h2>{user.title}</h2> */}
                            <h2>Responsável Comercial</h2>
                            {/* <p>{user.name}</p> */}
                            <p>Mateus Saad</p>
                            {/* <span>{user.phone}</span> */}
                            <p>44 999707380</p>{" "}
                        </ContainerTexts>
                    </ContainerImageUser>
                    <ButtonHelp href="https://api.whatsapp.com/send?phone=5544998483218&text=Olá,%20estava%20no%20site%20e%20preciso%20de%20ajuda!">
                        <p>Preciso de ajuda</p>
                        <FaWhatsapp color="#eee" />
                    </ButtonHelp>
                </ContainerHelper>
                <ListItem style={{ padding: "0px", width: "100%" }}>
                    <ListItemButton
                        style={{ padding: "0px", margin: "0", height: "38px", backgroundColor: "#eee" }}
                        onClick={() => {
                            logout();
                        }}
                    >
                        <TextOut>Sair</TextOut>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <ContainerMobile>
                <FiMenu onClick={toggleDrawer(true)} color="#938F65" size={30} />
                <Imagem onClick={clearFilter} src={logo} />
                <BtnHeader
                    onClick={() => {
                        navigation("/carrinho-clientes");
                    }}
                >
                    <img src={shoppingCart} alt="shopping-cart" width={25} />

                    {showCount && (
                        <ContainerCount style={{ bottom: "2vh", right: "5px" }}>{carrinho.length}</ContainerCount>
                    )}
                </BtnHeader>

                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
            </ContainerMobile>
        </>
    );
}
