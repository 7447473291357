import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    overflow-y: auto;
    gap: 5px;
    width: 95%;
    margin: 4px auto 10px auto;
`;

export const Cards = styled.button`
    border: 2px solid #875465;
    background-color: #d9d9d9;
    border-radius: 9px;
    padding: 2px 6px;
    color: #593843;
    min-width: 80px;
    &:active,
    &:hover {
        background-color: #875465;
        color: #eee;
    }
`;
