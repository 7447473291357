import React, { useState, useContext, useEffect } from "react";
import {
    ButtonRepetir,
    ContainerCard,
    ContainerCards,
    ContainerVisible,
    Titulo,
    Descricao,
    DivIcones,
    ContainerHidden,
    ContainerInfos,
    ContainerHeader,
    ContainerFooterCard,
    ContainerIcones,
    Infos,
    ListaProduto,
    ContainerItem,
    Imagem,
    DescricaoProduto,
    BtnQtd,
    TextoBtnQtd,
    Linha,
    BtnResumo,
    TextoResumo,
    TextRepetir,
    InputQtd,
    BtnMinus,
    BtnPlus,
} from "./styles";
import { FiMinus } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientesContext } from "../../contexts/clientes";
import { FaRegStar } from "react-icons/fa";
import { PiShoppingCartSimpleFill } from "react-icons/pi";
import { BsCart } from "react-icons/bs";
import { DesignServicesTwoTone } from "@mui/icons-material";
import { BsArrowRepeat } from "react-icons/bs";
import ModalRepetirPedido from "../../components/ModalRepetirPedido";

export default function CardsProdutos(props) {
    const { deleteCarrinho, removeNomeCarrinho, storeCarrinho, selectProduct } = useContext(ClientesContext);
    const [hiddenMenu, setHiddenMenu] = useState(true);
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;
    const [pedidoExiste, setPedidoExiste] = useState(false);
    const navigation = useNavigate();

    let pedidos = props.pedidos;

    async function handleRepetirCompra(array) {
        const carrinho = JSON.parse(localStorage.getItem("carrinho")) || [];
        if (isPedidoNoCarrinho(array, carrinho)) {
            setPedidoExiste(true);
            setTimeout(() => {
                setPedidoExiste(!true);
            }, 2500);

            return;
        }
        for (let element of array) {
            element.preco_venda = element.valor;
            await storeCarrinho(element);
        }
        navigation("/carrinho-clientes");
    }

    function isPedidoNoCarrinho(pedido, carrinho) {
        return pedido.every((itemPedido) =>
            carrinho.some(
                (itemCarrinho) =>
                    itemCarrinho.codigo_de_barras === itemPedido.codigo_de_barras &&
                    itemCarrinho.descricao === itemPedido.descricao &&
                    itemCarrinho.nome === itemPedido.nome
            )
        );
    }

    function moneyMask(price) {
        let value = "0";
        if (price) {
            value = price.toFixed(2);
        }

        value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
        let result = (+value / 100).toFixed(2);
        result = result < 0 || value === "" || result == null ? 0.0 : result;

        result = result.replace(".", ",");

        return "R$ " + result;
    }

    function sumCalc(type) {
        let result = 0;
        pedidos.map((target) => {
            return (result += target.valor * target.quantidade);
        });
        if (type == "sub") {
            return result;
        } else {
            if (result >= pedidos[0].pedido_frete_gratis) {
                return result;
            } else {
                return result + pedidos[0].valor_frete;
            }
        }
    }

    function calcFreteGratis() {
        let result = 0;
        pedidos.map((target) => {
            return (result += target.preco_venda * target.quantidade);
        });

        if (result - pedidos[0].pedido_frete_gratis < 0) {
            result = Math.abs(result - pedidos[0].pedido_frete_gratis);
        } else {
            result = 0;
        }

        return result;
    }

    if (!isMobilePage) {
        return (
            <ContainerCards>
                <ContainerCard>
                    <ContainerVisible>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Titulo>Pedido #{pedidos[0].id}</Titulo>

                            <BtnResumo
                                style={{
                                    borderColor:
                                        pedidos[0].status_pedido == "Concluido"
                                            ? "#206109"
                                            : pedidos[0].status_pedido == "Pendente"
                                            ? "#615914"
                                            : "#ef6969",
                                    background:
                                        pedidos[0].status_pedido == "Concluido"
                                            ? "#378B19"
                                            : pedidos[0].status_pedido == "Pendente"
                                            ? "#615914"
                                            : "#ef6969",
                                }}
                            >
                                <TextoResumo>{pedidos[0].status_pedido}</TextoResumo>
                            </BtnResumo>
                        </div>
                        <ContainerHidden
                            style={{
                                display: hiddenMenu ? "none" : "flex",
                            }}
                        >
                            {/* <ContainerInfos style={{ marginBottom: 5 }}>
                        </ContainerInfos> */}
                            {pedidos.map((element, i) => {
                                return (
                                    <ListaProduto key={i}>
                                        <ContainerItem>
                                            <Imagem
                                                src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${element.codigo_de_barras}.webp`}
                                            />
                                            <div>
                                                <DescricaoProduto>{element.descricao}</DescricaoProduto>
                                                <DescricaoProduto style={{ marginTop: "0.5vh" }}>
                                                    <strong>{moneyMask(element.valor)}</strong>
                                                </DescricaoProduto>
                                            </div>
                                            <BtnQtd style={{ justifyContent: "center" }}>
                                                <TextoBtnQtd>{element.quantidade}</TextoBtnQtd>
                                            </BtnQtd>
                                            <Titulo>{moneyMask(element.valor * element.quantidade)}</Titulo>
                                        </ContainerItem>
                                        <Linha />
                                    </ListaProduto>
                                );
                            })}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                <Titulo style={{ fontSize: "1.2rem" }}>{moneyMask(sumCalc("sub"))}</Titulo>
                                <Titulo
                                    style={{
                                        fontSize: "1.2rem",
                                        textDecoration: calcFreteGratis() == 0 ? "line-through 2px" : "",
                                    }}
                                >
                                    Frete: {moneyMask(pedidos[0].valor_frete)}
                                </Titulo>
                            </div>
                        </ContainerHidden>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Infos>
                                {pedidos[0].nome} - {pedidos[0].data_pedido}
                            </Infos>

                            <Titulo style={{ fontSize: "1.2rem" }}>Total {moneyMask(sumCalc())}</Titulo>
                        </div>
                    </ContainerVisible>
                    {hiddenMenu == true ? (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <FaAngleDown
                                onClick={() => {
                                    setHiddenMenu(!hiddenMenu);
                                }}
                                style={{ cursor: "pointer" }}
                                size={35}
                                color={"var(--background-clientes)"}
                            />
                        </div>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <FaAngleUp
                                onClick={() => {
                                    setHiddenMenu(!hiddenMenu);
                                }}
                                style={{ cursor: "pointer" }}
                                size={35}
                                color={"var(--background-clientes)"}
                            />
                        </div>
                    )}
                </ContainerCard>
            </ContainerCards>
        );
    } else {
        return (
            <ContainerCards>
                <ContainerCard>
                    <ContainerVisible>
                        <ContainerHeader>
                            <Titulo>Pedido #{pedidos[0].id}</Titulo>

                            <BtnResumo
                                style={{
                                    borderColor:
                                        pedidos[0].status_pedido == "Concluido"
                                            ? "#206109"
                                            : pedidos[0].status_pedido == "Pendente"
                                            ? "#615914"
                                            : "#ef6969",
                                    background:
                                        pedidos[0].status_pedido == "Concluido"
                                            ? "#875465"
                                            : pedidos[0].status_pedido == "Pendente"
                                            ? "#615914"
                                            : "#ef6969",
                                }}
                            >
                                <TextoResumo>{pedidos[0].status_pedido}</TextoResumo>
                            </BtnResumo>
                        </ContainerHeader>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Infos>
                                {pedidos[0].nome} - {pedidos[0].data_pedido}
                            </Infos>
                            {hiddenMenu && <Titulo style={{ fontSize: "14px" }}>Total {moneyMask(sumCalc())}</Titulo>}
                        </div>
                        <ContainerHidden
                            style={{
                                display: hiddenMenu ? "none" : "flex",
                            }}
                        >
                            {pedidos.map((element, i) => {
                                return (
                                    <ListaProduto key={i}>
                                        <ContainerItem>
                                            <Imagem
                                                src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${element.codigo_de_barras}.webp`}
                                            />
                                            <div>
                                                <DescricaoProduto>{element.descricao}</DescricaoProduto>
                                                <DescricaoProduto style={{ marginTop: "0.5vh" }}>
                                                    <strong>{moneyMask(element.valor)}</strong>
                                                </DescricaoProduto>
                                            </div>
                                            <BtnQtd style={{ justifyContent: "center" }}>
                                                <TextoBtnQtd>{element.quantidade}</TextoBtnQtd>
                                            </BtnQtd>
                                            {/* <Titulo>{moneyMask(element.valor * element.quantidade)}</Titulo> */}
                                        </ContainerItem>
                                        <Linha />
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "right",
                                                width: "50%",
                                                marginLeft: "auto",
                                                marginBottom: "-20px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Titulo style={{ fontSize: "0.9rem" }}>Total {moneyMask(sumCalc())}</Titulo>
                                        </div>
                                    </ListaProduto>
                                );
                            })}
                            <ContainerIcones>
                                {/* REPETIR COMPRA */}
                                <FaRegStar fill="#222" size={20} />
                                <ButtonRepetir onClick={() => handleRepetirCompra(pedidos)}>
                                    <DivIcones>
                                        <BsCart fill="#222" size={22} />
                                        <TextRepetir>Repetir compra</TextRepetir>
                                    </DivIcones>
                                    {pedidoExiste && (
                                        <ModalRepetirPedido message="Finalize sua compra antes de repetir o pedido novamente." />
                                    )}
                                </ButtonRepetir>
                            </ContainerIcones>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    width: "50%",
                                    marginLeft: "auto",
                                }}
                            >
                                <Titulo
                                    style={{
                                        fontSize: "0.9rem",
                                        textDecoration: calcFreteGratis() == 0 ? "line-through 2px" : "",
                                    }}
                                >
                                    Frete: {moneyMask(pedidos[0].valor_frete)}
                                </Titulo>
                            </div>
                        </ContainerHidden>
                    </ContainerVisible>
                    {hiddenMenu == true ? (
                        <ContainerFooterCard>
                            <FaAngleDown
                                onClick={() => {
                                    setHiddenMenu(!hiddenMenu);
                                }}
                                style={{ cursor: "pointer", padding: "0px", margin: "5px 10px -5px 0px" }}
                                size={15}
                                color={"gray"}
                            />
                        </ContainerFooterCard>
                    ) : (
                        <ContainerFooterCard>
                            <FaAngleUp
                                onClick={() => {
                                    setHiddenMenu(!hiddenMenu);
                                }}
                                style={{
                                    cursor: "pointer",
                                    padding: "0px",

                                    margin: "0px 10px -5px 0px",
                                }}
                                size={15}
                                color={"gray"}
                            />
                        </ContainerFooterCard>
                    )}
                </ContainerCard>
            </ContainerCards>
        );
    }
}
