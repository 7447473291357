import styled from "styled-components";

export const Container = styled.div`
    height: 45px;
    border-top: 1px solid #cdcdcd;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    position: fixed;
    bottom: 0;
    justify-content: center;
    width: 100%;
    background-color: #fff;

    > svg {
        height: 30px;
        display: flex;
        margin: 0 auto;
    }
    a > svg {
        height: 30px;
        display: flex;
        margin: 0 auto;
    }
    a > img {
        height: 25px;
        display: flex;
        margin: 0 auto;
    }
    > button {
        background-color: transparent;
        border: none;
    }
`;
