import React, { useContext } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { AuthContext } from "../../contexts/auth";

export default function ArrowSlideLeft({ handleClick }) {
    const { device } = useContext(AuthContext);

    return (
        <IoIosArrowBack
            size={device == "mobile" ? "30px" : "60px"}
            style={{
                position: "absolute",
                cursor: "pointer",
                zIndex: 1,
                left: device == "mobile" ? "0" : "1vw",
                color: "#999999",
                padding: device == "mobile" ? "0px" : "",
            }}
            onClick={handleClick}
        />
    );
}
