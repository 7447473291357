import React, { useState, useEffect, useContext } from "react";
// import { ContainerFavoritos, ListaFavoritos } from "./style";
import HeaderComponentMobile from "../../components/HeaderComponentMobile";
import { GrConfigure } from "react-icons/gr";
import { ClientesContext } from "../../contexts/clientes";

const Index = ({ produto }) => {
    // const { favoritos, isFavorite, handleFavorito } = useContext(ClientesContext);
    return (
        <div>
            <HeaderComponentMobile title="PRODUTOS FAVORITOS" />
            <div style={{ textAlign: "center" }}>
                <h3 style={{ color: "#ef6969", fontFamily: "Poppins" }}>Essa tela está em desenvolvimento.</h3>

                <GrConfigure color="#333" />
            </div>
            {/* <div>
                {favoritos.map((produto, index) => (
                    <div key={index}>{produto}</div>
                ))}
            </div> */}
        </div>
    );
};

export default Index;
