import React from "react";
import { useState } from "react";
import styles from "./PainelPedidos.module.css";
import { IoIosArrowDown } from "react-icons/io";
import { VscFilter } from "react-icons/vsc";
import { CiCirclePlus } from "react-icons/ci";
import { MdOutlineCancel } from "react-icons/md";
import ImprimirFornecedor from "../ImprimirFornecedores/ImprimirFornecedor";
import BotoesQuantidade from "../BotoesQuantidadeTabelaFornecedor/BotoesQuantidade";

const PainelPedidos = ({ onIncrement, onDecrement }) => {
    const [selectedValue, setSelectedValue] = useState("novo");

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [tableData, setTableData] = useState([]);

    const adicionarProduto = () => {
        // FUNCTION ADICIONAR NOVO PRODUTO
        const novoProduto = {
            nome: "Produto Cocamar Nome teste - Caixa 24 un - 900ml",
            marca: "Cocamar",
            codigo: "32563256",
            ean: "254133619623235",
            valor: "R$ 125,00",
            valorTotal: "R$ 0.00",
        };
        setTableData([...tableData, novoProduto]);
    };
    // FIM DA FUNCTION ADICIONAR NOVO PRODUTO

    // FUNCTION EXCLUIR UM PRODUTO
    const excluirProduto = (id) => {
        const novaTableData = tableData.filter((produto) => produto.id !== id);
        setTableData(novaTableData);
    };

    const [estadosPedidos, setEstadosPedidos] = useState({});

    const handleButtonClick = (pedidoPedido) => {
        setEstadosPedidos((prevState) => ({
            ...prevState,
            [pedidoPedido]: !prevState[pedidoPedido],
        }));
    };

    // FUNÇÃO FILTRAR STATUS PEDIDO
    const [filtroStatus, setFiltroStatus] = useState("");
    const handleFiltroChange = (event) => {
        setFiltroStatus(event.target.value);
    };

    const [search, setSearch] = useState("");
    const [expandir, setExpandir] = useState(false);
    const [filtroData, setFiltroData] = useState("");
    const [mostrarInputData, setMostrarInputData] = useState(false);

    const [pedido, setPedido] = useState([
        {
            pedido: 152369,
            hora: 13.47,
            data: 11_03_2024,
            forncedor: "Restaurante Marombitas",
            cnpj: 514369530114,
            nome_contato: "Pedro Henrique",
            numero_Contato: 44999703280,
            endereco: "Rua Beijamin Fernandes Dias, 151, Zona 1, 87020250, Maringá-PR",
            recebimento: "Segunda a sexta a partir das 10:30",
            prazo: "1 Dia",
            total: 141.6,
            isCompleted: false,
        },
        {
            pedido: 156342,
            hora: 13.47,
            data: "11_03_2024",
            forncedor: "Restaurante Marombitas",
            cnpj: 51436953000114,
            nome_contato: "Pedro Henrique",
            numero_Contato: 44999703280,
            endereco: "Rua Beijamin Fernandes D, Zona 1, 87020250, Maringá-PR",
            recebimento: "Segunda a sexta a partir das 14:30",
            prazo: "7 Dia",
            total: 321.6,
            isCompleted: false,
        },
    ]);

    return (
        <div className={styles.primary_container}>
            <div className={styles.pedido_list}>
                <div className={styles.painel_container}>
                    <h1 className={styles.titulo_pedidos}>Painel de Pedidos</h1>

                    <div className={styles.container_filter}>
                        {/* FILTRO DE STATUS */}
                        <div className={styles.search_status_container}>
                            <VscFilter />
                            <input
                                className={styles.input_status}
                                type="text"
                                value={filtroStatus}
                                onChange={handleFiltroChange}
                                placeholder="Filtro Status"
                            />
                        </div>
                        {/* FILTRO DE DATA */}
                        <div className={styles.filtro_data_container}>
                            <p
                                className={`${styles.input_status} `}
                                onClick={() => setMostrarInputData(!mostrarInputData)}
                            >
                                {" "}
                                <VscFilter />
                                Filtro Data
                            </p>
                            {mostrarInputData && (
                                <input
                                    type="date"
                                    value={filtroData}
                                    onChange={(e) => setFiltroData(e.target.value)}
                                    placeholder="Filtro Data"
                                />
                            )}
                        </div>
                    </div>
                </div>

                {pedido
                    .filter((pedido) => {
                        const filtroStatusCondicao =
                            filtroStatus === "" ||
                            filtroStatus === "novo" ||
                            estadosPedidos[pedido.pedido] === filtroStatus;

                        const filtroDataCondicao = filtroData === "" || pedido.data === filtroData;
                        return filtroStatusCondicao && filtroDataCondicao;
                    })

                    .map((pedido) => (
                        <div
                            key={pedido.pedido}
                            className={`${styles.pedido} ${estadosPedidos[pedido.pedido] ? `${styles.ativa}` : ""}`}
                        >
                            <div className={styles.content}>
                                <div className={styles.dados}>
                                    <p>
                                        Pedido#{pedido.pedido} -{pedido.hora} -{pedido.data}
                                    </p>
                                </div>

                                <div className={styles.sombra}>
                                    <div className={styles.fornecedor}>
                                        <p className="fornecedor_name">
                                            {pedido.forncedor}
                                            {pedido.cnpj}
                                        </p>
                                    </div>
                                    <br></br>
                                    <div className={styles.contato}>
                                        <p>
                                            {pedido.nome_contato},{pedido.numero_Contato}
                                        </p>
                                    </div>
                                    <p className={styles.endereco}>{pedido.endereco}</p>
                                    <p className={styles.recebimento}>{pedido.recebimento}</p>
                                </div>
                                <div className={styles.dados_pagamento}>
                                    <p>Prazo de Envio de {pedido.prazo} (s)</p>
                                    <p className={styles.total_pedido}>Total R$ {pedido.total}</p>
                                </div>
                                {/* TABELA INTERNA , DETALHES DO PEDIDO  */}
                                <table
                                    id="tabelaInformacoes"
                                    className={` ${styles.scrollableTableWrapper} ${styles.tabela_element} ${
                                        estadosPedidos[pedido.pedido] ? "ativa" : ""
                                    }`}
                                    style={{ display: estadosPedidos[pedido.pedido] ? "inline-block" : "none" }}
                                >
                                    <div className={styles.main_container_table}>
                                        <div className={styles.header_table_elements}>
                                            <div className={styles.print_icon}>
                                                <ImprimirFornecedor />
                                            </div>

                                            <div className={styles.container_titulos_tabela}>
                                                <div className={styles.table_header}>Nome do Produto</div>
                                                <div className={`${styles.table_header} ${styles.nome_produto}`}>
                                                    Marca
                                                </div>
                                                <div className={`${styles.table_header} ${styles.nome_produto}`}>
                                                    Código
                                                </div>
                                                <div className={styles.table_header}>EAN</div>
                                                <div className={styles.table_header}>Valor</div>
                                                <div className={styles.table_header}>Quantidade</div>
                                                <div className={styles.table_header}>Valor Total</div>
                                            </div>
                                        </div>
                                        {/* COLUNAS DA TABELA */}
                                        <div className={styles.container_colunas_tabela}>
                                            {tableData.map((produto, index) => (
                                                <div key={index} className={styles.container_colunas}>
                                                    <div className={styles.table_colum}>{produto.nome}</div>
                                                    <div className={styles.table_colum}>{produto.marca}</div>
                                                    <div className={styles.table_colum}>{produto.codigo}</div>
                                                    <div className={`${styles.produto_ean} ${styles.table_colum}`}>
                                                        {produto.ean}
                                                    </div>
                                                    <div className={styles.table_colum}>{produto.valor}</div>
                                                    <BotoesQuantidade id="quantidade" />
                                                    <div className={styles.table_colum}>{produto.quantidade}</div>
                                                    <button
                                                        onClick={() => excluirProduto(produto.id)}
                                                        className={styles.button_cancel}
                                                    >
                                                        <MdOutlineCancel />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        {/* FIM DAS COLUNAS TABELA */}
                                    </div>
                                </table>
                                {/* FIM DA TABELA  */}
                                <div className={styles.container_footer_tabela}>
                                    <div
                                        className={`${styles.adicionar_produto} ${
                                            estadosPedidos[pedido.pedido] ? "ativa" : ""
                                        }`}
                                        style={{ display: estadosPedidos[pedido.pedido] ? "flex" : "none" }}
                                    >
                                        <CiCirclePlus />
                                        <button onClick={adicionarProduto}>Adicionar Produto</button>
                                    </div>

                                    <div className={styles.container_valores}>
                                        <div
                                            className={`${styles.valores_pedido}  ${
                                                estadosPedidos[pedido.pedido] ? "ativa" : ""
                                            }`}
                                            style={{ display: estadosPedidos[pedido.pedido] ? "flex" : "none" }}
                                        >
                                            <div className={styles.valores_item}>
                                                <p>Subtotal </p>
                                                <p>Taxa Entrega </p>
                                                <p>Desconto</p>
                                            </div>
                                            <div className={styles.valores_item}>
                                                <p>R$ 150.00</p>
                                                <p>R$ 10.00</p>
                                                <p>
                                                    <input type="text" className={styles.input_desconto}></input>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* SECTION COM AS OPTIONS : NOVO /CONCLUIDO / CANCELADO */}
                                <select
                                    className={`${styles.options} ${styles[selectedValue]}`}
                                    value={selectedValue}
                                    onChange={handleChange}
                                >
                                    <option value="novo">Novo</option>
                                    <option value="aberto">Aberto</option>
                                    <option value="cancelado">Cancelado</option>
                                    <option value="concluido">Concluído</option>
                                </select>
                                <div className={styles.div_button}>
                                    {/* BOTAO EXPANDS PEDIDO */}
                                    <button
                                        className={`${styles.expands_button} ${
                                            estadosPedidos[pedido.pedido] ? styles.rotacionado : ""
                                        }`}
                                        onClick={() => handleButtonClick(pedido.pedido)}
                                    >
                                        <IoIosArrowDown />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default PainelPedidos;
