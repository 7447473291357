import React from "react";
import { ModalBackdrop, Modal, Titulo, Text } from "./style";

const index = ({ onCancel, onConfirm, message }) => {
    return (
        <ModalBackdrop>
            <Modal>
                <Titulo>Atenção</Titulo>
                <Text>{message}</Text>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                    <button style={{ backgroundColor: "#875465", color: "#eee", border: "none" }} onClick={onCancel}>
                        Cancelar
                    </button>
                    <button
                        style={{ backgroundColor: " #97C018", border: "none", color: "#eee", fontWeight: "500" }}
                        onClick={onConfirm}
                    >
                        Confirmar
                    </button>
                </div>
            </Modal>
        </ModalBackdrop>
    );
};

export default index;
