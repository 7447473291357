import styled from "styled-components";

export const ContainerError = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const TextoError = styled.h3`
    color: #ef6969;
    margin: 0;
    font-size: 13px;
    margin-top: 5px;
`;
