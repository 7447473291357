import React from "react";
import { ContainerError, TextoError } from "./styles";

export default function MsgError(props) {
    return (
        <ContainerError>
            <TextoError>{props.mensagem}</TextoError>
        </ContainerError>
    );
}
