import React from "react";
import { ModalBackdrop, Modal, Titulo, Text } from "./style";
import { FiAlertTriangle } from "react-icons/fi";

const index = ({ onConfirm, message }) => {
    return (
        <ModalBackdrop>
            <Modal>
                <Titulo>
                    <FiAlertTriangle fill="#875465" size={45} />
                </Titulo>
                <Titulo>O pedido ja está no carrinho</Titulo>
                <Text>{message}</Text>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}></div>
            </Modal>
        </ModalBackdrop>
    );
};

export default index;
