import React, { createContext, useEffect, useState } from "react";

export const ClientesContext = createContext({});

function ClientesProvider({ children }) {
    const [chosenProduct, setChosenProduct] = useState(null);
    const [productEnter, setProductEnter] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [produtos, setProdutos] = useState(null);
    const [produtosFiltrados, setProdutosFiltrados] = useState(null);
    const [filtroTexto, setFiltroTexto] = useState(null);
    const [filtrosProdutos, setFiltrosProdutos] = useState([{ categoria_2: "all" }, { nome: "all" }]);
    const [titleProdutos, setTitleProdutos] = useState("Produtos > Sugeridos");
    const [listaFornecedores, setListaFornecedores] = useState([]);
    const [carrinho, setCarrinho] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [showHeader, setShowHeader] = useState(true);
    const [favoritos, setFavoritos] = useState([]);
    const [isFavorite, setIsFavorite] = useState(false);
    const [produtoSelecionado, setProdutoSelecionado] = useState(null);

    const handleIcone = () => {
        setIsFavorite((prevState) => !prevState);
    };
    const handleFavorito = (produto) => {
        console.log(22, favoritos);
        const isFavorito = favoritos.find((fav) => fav.id === produto.id);
        if (isFavorito) {
            setFavoritos(favoritos.filter((fav) => fav.id !== produto.id));
            handleIcone();
        } else {
            setFavoritos([...favoritos, produto]);
            handleIcone();
        }
        console.log(44, favoritos);
    };

    function selectProduct(target) {
        setChosenProduct(target);
    }

    const selectProductEnter = (bool, product) => {
        setProductEnter(bool);
        setSelectedProduct(product);
        setShowHeader(false);
    };

    function changeFiltro(filtro, value) {
        if (filtro === "reset" && value === "reset") {
            setFiltrosProdutos([{ categoria_2: "all" }, { nome: "all" }]);
            setProdutosFiltrados(null);
            setFiltroTexto(null);
            setTitleProdutos("Produtos > Sugeridos");
            return;
        }

        let arrayFiltro = filtrosProdutos;

        let index = arrayFiltro.findIndex((a) => Object.keys(a)[0] == filtro);
        if (filtro == "categoria_2") {
            arrayFiltro[index].categoria_2 = value;
        }
        if (filtro == "nome") {
            arrayFiltro[index].nome = value;
        }
        setFiltrosProdutos(arrayFiltro);

        setTitleProdutos("Produtos > " + value);

        aplicaFiltro();
    }

    function aplicaFiltro() {
        let arrayProdutos = filtroTexto ?? produtos;

        filtrosProdutos.map((filtro) => {
            arrayProdutos = arrayProdutos.filter((item) => {
                return Object.keys(filtro).every((key) => {
                    if (filtro[key] == "all") return item;
                    return filtro[key] == item[key];
                });
            });
            return filtro;
        });
        setProdutosFiltrados(
            arrayProdutos.sort((a, b) => {
                if (a.descricao < b.descricao) return -1;
            })
        );
    }

    function searchProdutos(value) {
        let result = null;
        let array = produtosFiltrados ?? produtos;
        result = array.filter((item) => {
            if (item.descricao.toLowerCase().includes(value.toLowerCase())) {
                return item;
            }
        });
        setFiltroTexto(
            result.sort((a, b) => {
                if (a.descricao < b.descricao) return -1;
            })
        );
    }

    function storeProdutos(value) {
        setProdutos(value);
        let fornecedores = [];
        value.forEach((element) => {
            fornecedores.push({ value: element.nome, label: element.nome });
        });
        fornecedores = fornecedores.filter(
            (value, index, self) => index === self.findIndex((t) => t.nome === value.nome && t.label === value.label)
        );
        setListaFornecedores(
            fornecedores.sort((a, b) => {
                if (a.label < b.label) return -1;
            })
        );
    }

    function storePaginacao(array) {
        let targetArray = [];
        if (array) {
            targetArray = array;
        }
        targetArray = targetArray.sort((a, b) => {
            if (a.descricao < b.descricao) return -1;
        });
        let i = 0;
        const result = targetArray.reduce(
            (matrix, item) => {
                if (!matrix.targetArray.length || i % 12 == 0) matrix.targetArray.push([item]);
                else matrix.targetArray[matrix.targetArray.length - 1].push(item);

                i++;
                return matrix;
            },
            { targetArray: [] }
        ).targetArray;
        return result;
    }

    function storeCarrinho(item) {
        return new Promise((resolve) => {
            // Recupera o carrinho do localStorage ou inicializa como um array vazio
            let array = JSON.parse(localStorage.getItem("carrinho")) || [];

            // Verifica se array é um array válido
            if (!Array.isArray(array)) {
                array = [];
            }

            // Verifica se o produto já existe no carrinho
            let produtoExiste = array.find(
                (a) =>
                    a.codigo_de_barras === item.codigo_de_barras &&
                    a.descricao === item.descricao &&
                    a.nome === item.nome
            );
            if (!produtoExiste) {
                array.push(item);
            } else {
                produtoExiste.quantidade = item.quantidade;
            }
            let arrayString = JSON.stringify(array);
            localStorage.setItem("carrinho", arrayString);
            setCarrinho(array);
            resolve();
        });
    }

    function deleteCarrinho(item) {
        let array = [...carrinho];

        let index = array.findIndex(
            (a) =>
                a.codigo_de_barras === item.codigo_de_barras && a.descricao === item.descricao && a.nome === item.nome
        );
        array = array.filter((a, i) => i != index);
        let arrayString = JSON.stringify(array);
        localStorage.setItem("carrinho", arrayString);
        setCarrinho(array);
    }

    function createListaCarrinho(array) {
        return array.reduce(function (key, element) {
            key[element.nome] = key[element.nome] || [];
            key[element.nome].push(element);
            return key;
        }, Object.create(null));
    }

    function storeCarrinhoLogin(array) {
        setCarrinho(array);
        localStorage.setItem("carrinho", array);
    }

    function limpaCarrinho() {
        setCarrinho([]);
        localStorage.removeItem("carrinho");
    }

    function removeNomeCarrinho(pedidos) {
        let array = [...carrinho];

        pedidos.forEach((item) => {
            let index = array.findIndex(
                (a) =>
                    a.codigo_de_barras === item.codigo_de_barras &&
                    a.descricao === item.descricao &&
                    a.nome === item.nome
            );
            array = array.filter((a, i) => i != index);
        });
        let arrayString = JSON.stringify(array);
        localStorage.setItem("carrinho", arrayString);
        setCarrinho(array);
    }

    function storePedidos(pedidos) {
        setPedidos(pedidos);
    }

    function createListaPedidos(array) {
        return array.reduce(function (key, element) {
            key[element.id] = key[element.id] || [];
            key[element.id].push(element);
            return key;
        }, Object.create(null));
    }

    return (
        <ClientesContext.Provider
            value={{
                chosenProduct,
                filtrosProdutos,
                produtos,
                produtosFiltrados,
                filtroTexto,
                titleProdutos,
                listaFornecedores,
                carrinho,
                chosenProduct,
                pedidos,
                productEnter,
                selectedProduct,
                showHeader,
                favoritos,
                isFavorite,
                setShowHeader,
                setCarrinho,
                selectProduct,
                selectProductEnter,
                changeFiltro,
                storeProdutos,
                storePaginacao,
                searchProdutos,
                storeCarrinho,
                createListaCarrinho,
                deleteCarrinho,
                storeCarrinhoLogin,
                setChosenProduct,
                handleFavorito,
                limpaCarrinho,
                removeNomeCarrinho,
                storePedidos,
                createListaPedidos,
            }}
        >
            {children}
        </ClientesContext.Provider>
    );
}

export default ClientesProvider;
