import React from 'react'
import MenuNavegacao from '../MenuNavegacaoFornecedores';
import PainelPedidos from '../PainelPedidosFornecedores/PainelPedidos'
import styles from './ContainerFornecedores.module.css'

const ContainerFornecedores = () => {



  return (
    <div className={styles.container_fornecedores}>
       <MenuNavegacao/>
       <div className={styles.container_pedidos}>
        <PainelPedidos key={PainelPedidos.pedido}/>
       </div>

    </div>
  )
}

export default ContainerFornecedores
