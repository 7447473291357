import styled from "styled-components";
import { Link } from 'react-router-dom';

export const LinkReact = styled(Link)`
text-decoration:none;
list-style:none;
color:white;
`;

export const LinkNavegacao = styled.ul`
list-style: none;
text-decoration:none;
overflow: hidden;
`;

export const LinksMenu = styled.li`
list-style: none;
`;

export const Sombra = styled.div`
&:hover {background-color: #3f2930;
border-radius: 20px;
}
`
export const AncoraNav = styled.a`
text-decoration:none;
display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 15px;
line-height: 50px;
text-decoration: none;
padding: 10px 5% 10px 50px;
font-size: 20px;
margin-bottom: 0px;
display: flex;
`;

export const SpanIcon = styled.span`
display: flex;
flex-direction: column;
transition: 1s;
`;

export const TextLink = styled.span`
display: inline-block;
padding-left: 37px;
padding-right: 10px;
color: #fff;
text-decoration:none;
list-style:none;
> Link {
    text-decoration:none;
    background-color:green;
}
`;
