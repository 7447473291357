import React, { useContext } from "react";
import { FornecedoresContext } from "../../contexts/fornecedores";
import HeaderFornecedores from "../../components/HeaderFornecedores/HeaderFornecedores"
import ContainerFornecedores from "../../components/ContainerPrincipalFornecedores/ContainerFornecedores";

export default function Fornecedores() {
    return (
        <div >
            <HeaderFornecedores/>
            <ContainerFornecedores/>
        </div>
    );
}
