import React from 'react'
import { useState } from 'react'
import styles from './DemandaFornecedores.module.css'

const DemandaFornecedores = () => {
  const [demanda,setDemanda] =useState([

    {
      id:1,
      text:'Nome do produto tamanho P',
      ean:'00000000000',
      tamanhos:'900 ml',
      fornecedor: 'Camilo Atacado',
      preco:24.90,
      demanda_mes:2500,
      oferta:'22.50',
      validade:'25/05/2024'
    }
  ])
  
  
  return (
<div className={styles.container_main_demanda}>

  <div>
    <h2 className={styles.titulo_demanda}>Gerenciamento de ofertas</h2>

  </div>
    <div className={styles.container_demanda}>
      <div className={styles.header_demanda}>
        <span>Produto</span>
        <span>EAN</span>
        <span>Tamanho</span>
        <span>Fornecedor</span>
        <span>Preço</span>
        <span>Demanda/Mês</span>
        <span>Oferta</span>
        <span>Validade</span>
      </div>
      <div>
        {demanda
        .map((demanda) => (
          <div key={demanda.id}>
            <div className={styles.content_demanda}>
              <div>{demanda.text}</div>
              <div>{demanda.ean}</div>
              <div>{demanda.tamanhos}</div>
              <div>{demanda.fornecedor}</div>
              <div>R${demanda.preco}</div>
              <div>{demanda.demanda_mes}</div>
              <div>{demanda.oferta}</div>
              <div>{demanda.validade}</div>
            </div>
          
            <div className={styles.content_demanda}>
              <div>{demanda.text}</div>
              <div>{demanda.ean}</div>
              <div>{demanda.tamanhos}</div>
              <div>{demanda.fornecedor}</div>
              <div>R${demanda.preco}</div>
              <div>{demanda.demanda_mes}</div>
              <div>{demanda.oferta}</div>
              <div>{demanda.validade}</div>
            </div>
          </div>
          

        ))}
       

      </div>

    </div>
    </div>
  )
}

export default DemandaFornecedores
