import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ClientesContext } from "./clientes";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [userDimensions, setUserDimensions] = useState(null);
    const [device, setDevice] = useState(null);
    const navigation = useNavigate();
    const { carrinho, setCarrinho, storeCarrinhoLogin } = useContext(ClientesContext);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }

    async function loginApp(data) {
        let resposta = null;
        await axios
            .post(`${process.env.REACT_APP_BASE_HTTP}/login`, data)
            .then((response) => {
                setUser(response.data);
                setUserDimensions(getWindowDimensions());
                //   salvando usuario  //
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("token", response.data.token);
                setToken(response.data.token);
                setLoggedIn(true);
                resposta = true;
            })
            .catch((error) => {
                console.log(error);
                resposta = false;
            });
        return resposta;
    }

    function logoutApp() {
        setUser(null);
        setToken(null);
        setLoggedIn(false);
        localStorage.clear();
    }

    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                device,
                user,
                token,
                userDimensions,
                loginApp,
                logoutApp,
                setDevice,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
