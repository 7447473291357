import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import { ClientesContext } from "../../contexts/clientes";
import { useNavigate } from "react-router-dom";
import {
    Container,
    ContainerMobile,
    ContainerLogin,
    ContainerSign,
    ContainerItems,
    ContainerItemsMobile,
    DivInputs,
    ImagemSimplificada,
    Imagem,
    TextSign,
    BtnSign,
    InputLogin,
    InputLoginMobile,
    BtnLogin,
    BtnLoginMobile,
    TextMobile,
    TextReset,
} from "./styles";
import logo from "../../assets/img/logo.png";
import logoMobile from "../../assets/img/alimenz-logo-white.png";
import logoSimplificada from "../../assets/img/logo-simplificada.png";
import MsgError from "../../components/MsgError";

export default function Login() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const { loginApp, setDevice } = useContext(AuthContext);
    const { storeCarrinhoLogin } = useContext(ClientesContext);
    const [loginUsuario, setLogin] = useState("");
    const [senhaUsuario, setSenha] = useState("");
    const [errorLogin, setErrorLogin] = useState(false);
    const navigation = useNavigate();

    async function login() {
        handleWindowSizeChange();
        let dataLogin = {
            usuario: loginUsuario,
            senha: senhaUsuario,
        };
        const auth = await loginApp(dataLogin);
        if (auth) {
            setErrorLogin(false);
            let carrinho = localStorage.getItem("carrinho");
            let arrayCarrinho = JSON.parse(carrinho);
            if (arrayCarrinho) {
                storeCarrinhoLogin(arrayCarrinho);
            }
            navigation("/produtos-clientes");
        } else {
            setErrorLogin(true);
        }
    }

    function handleWindowSizeChange() {
        setDevice(window.innerWidth <= 768 ? "mobile" : "desktop");
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            login();
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [window.innerWidth]);

    const isMobile = width <= 768;

    if (!isMobile) {
        return (
            <Container>
                <ContainerLogin>
                    <ContainerItems>
                        <Imagem src={logo} alt="logo" />
                        <InputLogin placeholder="Email" type="text" onChange={(text) => setLogin(text.target.value)} />
                        <InputLogin
                            placeholder="Senha"
                            type="password"
                            onChange={(text) => setSenha(text.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />

                        <BtnLogin onClick={() => login()}>ENTRAR</BtnLogin>
                    </ContainerItems>
                    {errorLogin && <MsgError mensagem="Falha no login, verifique o usuário/senha." />}
                </ContainerLogin>
                <ContainerSign>
                    <ContainerItems>
                        <ImagemSimplificada src={logoSimplificada} alt="logo simplificada" />
                        <TextSign>Não tem uma conta ainda?</TextSign>
                        <BtnSign href="https://api.whatsapp.com/send?phone=+555544998483218&text=Ol%C3%A1%2C+vim+pelo+site+da+Alimenz+e+preciso+de+ajuda+para+me+cadastrar.">
                            CRIAR CONTA
                        </BtnSign>
                    </ContainerItems>
                </ContainerSign>
            </Container>
        );
    } else {
        return (
            <ContainerMobile>
                <ContainerItemsMobile>
                    <div
                        style={{
                            height: "28vh",
                            display: "flex",
                            alignItems: "end",
                        }}
                    >
                        <Imagem
                            style={{ width: width * 0.65, maxWidth: "320px", marginLeft: "15px" }}
                            src={logoMobile}
                            alt="logo"
                        />
                    </div>
                    <DivInputs>
                        <div>
                            <h2>Nome de usuário</h2>
                            <InputLoginMobile type="text" onChange={(text) => setLogin(text.target.value)} />
                        </div>
                        <div>
                            <h2>Senha</h2>
                            <InputLoginMobile
                                type="password"
                                onChange={(text) => setSenha(text.target.value)}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                    </DivInputs>
                </ContainerItemsMobile>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <BtnLoginMobile onClick={() => login()}>Entrar</BtnLoginMobile>
                    <TextReset style={{ fontWeight: "400" }}>Esqueceu a senha?</TextReset>
                    <TextMobile style={{ marginTop: "10%", textShadow: "0 6px 4px rgba(0 , 0, 0, 0.25)" }}>
                        Não tem uma conta ainda? <br></br>
                        <span style={{ textDecoration: "underline" }}>
                            {" "}
                            <a href="https://api.whatsapp.com/send?phone=5544998483218&text=Olá,%20gostaria%20de%20criar%20uma%20conta%20na%20Alimenz">
                                Criar conta
                            </a>
                        </span>
                    </TextMobile>
                    {errorLogin && <MsgError mensagem="Falha no login, verifique o usuário/senha." />}
                </div>
            </ContainerMobile>
        );
    }
}
