
import React, { useContext } from "react";
import { FornecedoresContext } from "../../contexts/fornecedores";
import HeaderFornecedores from "../../components/HeaderFornecedores/HeaderFornecedores"
import ContainerDemanda from "../../components/ContainerFornecedoresDemanda/ContainerDemanda";


const ForncedoresDemanda = () => {
  return (
    <div>
        <HeaderFornecedores/>
         <ContainerDemanda/>
    </div>
  )
}

export default ForncedoresDemanda
