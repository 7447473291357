import styled from "styled-components";
import { FiMinus } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";

export const ButtonsMobile = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    > button {
        background-color: #875465;
        color: white;
        border: none;
        display: flex;
        height: 30px;
        align-items: center;
        width: 100%;
    }
`;

export const ButtonsPagination = styled.div``;

export const ContainerGeral = styled.div`
    padding: 0 8vw;
    margin-top: 2vh;
    @media (max-width: 425px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const ContainerGeralMobile = styled.div`
    margin-top: 2vh;
    height: 100%;
`;

export const ContainerCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 1vh;
`;

export const ContainerCardsMobile = styled.div`
    justify-items: left;
    padding: 2px 5px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    gap: 1vh;
    overflow-x: hidden;
    padding-bottom: 15px;
    transition: all 300ms ease-in;
    overflow-y: scroll;
    height: 60% !important;
`;

export const ContainerTextosMobile = styled.div`
    padding: 20px;
`;

export const ContainerMainMobile = styled.div`
    display: flex;
    height: 84%;
    gap: 15px;
    flex-direction: column;
    @media (max-height: 600px) {
        height: 75%;
    }
    /* DIMENSIONS FOR GALAXY S8 +*/
    @media (min-width: 355px) and (max-width: 365px) and (min-height: 735px) and(max-height: 745px) {
        height: 54%;
    }
    /*DIMENSIONS FOR IPHONE XR */
    @media (min-width: 410px) and (max-width: 418px) and (min-height: 890px) and (max-height: 899px) {
        height: 105%;
    }

    /* DIMENSIONS FOR IPHONE 12 PRO */
    @media (min-width: 385px) and (max-width: 390px) and (min-height: 840px) and (max-height: 848px) {
        height: 100%;
    }
    /* DIMENSIONS FOR IPHONE 14 PRO */
    @media (min-width: 425px) and (max-width: 435px) and (min-height: 928px) and (max-height: 935px) {
        height: 106%;
    }
    /*@media (min-height: 925px) {
        grid-template-rows: 64vh 10vh;
    } */
`;
export const TituloPagina = styled.h1`
    color: var(--background-clientes-escuro);
    font-size: 1.4rem;
    margin: 0;
    padding-bottom: 5px;
`;

export const TextoBtnTitulo = styled.h1`
    color: var(--background-clientes-escuro);
    font-size: 1.4rem;
    cursor: pointer;
    margin: 0;
    text-decoration: underline;
    padding-bottom: 5px;
`;

export const ContainerProduto = styled.div`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    border-radius: 20px;
    padding: 2vh 4vw;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
`;

export const ContainerProdutoMobile = styled.div`
    margin: 0;
    min-height: 75vh;
    > span {
        display: flex;
        margin-left: auto;
        justify-content: end;
        width: 50vw;
        > button {
            color: #555;
            top: 60px;
            right: 0px;
            background-color: transparent;
            border: none;
            > img {
                height: 35px;
                top: 10px;
                right: 14px;
            }
        }
    }
`;
export const ContainerImage = styled.div`
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    height: 25vh;
    display: flex;
    padding-top: 15px;
    align-items: end;
    justify-content: center;
`;

export const ContainerTexto = styled.div``;

export const ContainerPrecos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
`;

export const ContainerHeaderDetalhe = styled.div`
    margin-top: -10px;
    /* border-bottom: 1px solid #3333332e; */
    display: grid;
`;

export const IconMinus = styled(FiMinus)`
    font-size: 12px;
    color: #000;
    z-index: 1;
    @media (max-width: 768px) {
        color: #fff !important;
    }
`;

export const IconPlus = styled(FiPlus)`
    font-size: 12px;
    color: #000;
    z-index: 1;
    @media (max-width: 768px) {
        color: #fff !important;
    }
`;

export const TituloProduto = styled.h1`
    color: #000000;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    @media (max-width: 768px) {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1rem;
        overflow: scroll;
    }
    @media (max-width: 340px) {
        font-size: 0.9rem;
    }
`;

export const InformacoesProduto = styled.h2`
    color: #999999;
    font-size: 1.2rem;
    padding-top: 10px;
    font-weight: 400;
    margin: 0;
    @media (max-width: 550px) {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0px;
    }
    @media (min-width: 550px) {
        padding-top: 0px;
    }
`;

export const ImagemProduto = styled.img`
    object-fit: cover;
    overflow: hidden;
    @media (max-width: 768px) {
        max-width: 300px !important;
        max-height: 170px;
    }
`;

export const PrecoPrincipal = styled.h1`
    color: var(--background-clientes);
    font-size: 1.3rem;
    font-weight: 700;
    margin: 0;
    text-align: center;
    @media (max-width: 550px) {
        font-weight: 700;
        font-style: normal;
        font-size: 1rem;
    }
`;

export const InformacoesPreco = styled.h2`
    color: var(--background-clientes);
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
`;

export const BtnQtd = styled.div`
    padding: 0;
    border-radius: 20px;
    background-color: var(--branco);
    display: flex;
    flex-direction: row;
    height: 3vh;
    justify-content: space-between;
    width: 70%;
    border: 1px solid var(--background-clientes-claro);
    margin-bottom: 10px;
    @media (max-width: 600px) {
        height: 28px;
    }
    @media (max-width: 768px) {
        background-color: #875465;
        height: 30px;
        border: none;
    }
`;

export const TextoBtnQtd = styled.h2`
    font-size: 0.8rem;
`;

export const InputQtd = styled.input`
    width: 70%;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
    outline-color: var(--background-clientes-claro);
    @media (max-width: 768px) {
        background-color: #875465;
        color: #fff;
    }
`;

export const BtnMinus = styled.button`
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    border-right: 1px solid var(--background-clientes-claro);
    align-items: center;
    @media (max-width: 768px) {
        border: none;
    }
`;

export const BtnPlus = styled.button`
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
    border-left: 1px solid var(--background-clientes-claro);
    align-items: center;
    @media (max-width: 768px) {
        border: none;
    }
`;

export const BtnAdc = styled.button`
    width: 70%;
    border: 2px solid #999999;
    border-radius: 20px;
    background-color: var(--branco);
    cursor: pointer;
    z-index: 1;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    color: var(--background-clientes-escuro);
    font-weight: 800;
    margin-top: 1vh;
    &:hover {
        color: var(--branco);
    }
    &:hover {
        background-color: var(--background-clientes-claro);
    }
`;

export const BtnAdcMobile = styled.button`
    border-radius: 20px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 800;
    margin-top: -7px;
    &:hover {
        color: var(--branco);
    }
    &:hover {
        background-color: var(--background-clientes-claro);
    }
    @media (max-width: 425px) {
        background-color: #875465;
        color: #fff;
        width: 100%;
        font-weight: 500;
        height: 23px;
        border: none;
    }
    @media (min-width: 600px) {
        margin-top: -8px;
    }
`;

export const ContainerRelacionados = styled.div`
    margin-top: 2vh;
`;

export const ContainerPrecoMedio = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
`;

export const ContainerPagination = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    position: relative;
    @media (max-width: 768px) {
        margin-top: 1vh;
        padding-bottom: 5px;
    }
`;

export const ContainerButtonsPagination = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--background-clientes-claro);
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    @media (max-width: 650px) {
        height: 30px;
        > div {
            display: flex;
            height: 30px;
            width: 80vw !important;
            overflow: hidden;
        }
    }
    @media (max-width: 768px) {
        height: 30px;
        > div {
            display: flex;
            justify-content: center;
            height: 30px;
            width: 60vw;
            overflow: hidden;
        }
        @media (min-width: 770px) and (max-width: 912px) {
        }
    }
`;

export const ContainerPrevNext = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        background-color: var(--background-clientes-claro);
        color: #fff;
    }
`;

export const BotaoPagination = styled.button`
    height: 40px;
    width: 40px;
    font-weight: bold;
    font-size: 1rem;
    background-color: #ffffff;
    color: #615f4a;
    border: none;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        background-color: var(--background-clientes-claro);
        color: #fff;
    }
`;

export const ContainerClose = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const CtnPrecoPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 550px) {
        flex-direction: row;
        align-items: center;
        gap: 40px;
    }
`;

export const SubCtnTextos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    @media (min-width: 550px) {
        align-items: center;
    }
`;

export const DivDuvidas = styled.details`
    width: 70%;
    display: flex;
    border-bottom: 1px solid #cdcdcd;
    border-radius: 9px;
    padding: 3px 6px;
    margin: 0 auto;
`;

export const BtnContato = styled.a`
    display: block;
    width: 60%;
    text-decoration: none;
    text-align: center;
    margin: 0 auto;
    border: none;
    border: 2px solid #875465;
    border-radius: 9px;
    color: #111;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-top: 10px;
    &:active,
    &:hover {
        background-color: #a6a167;
        border: 2px solid #a6a167;
    }
`;
