import React, { useState } from "react";
import { Container } from "./style";
import { PiHouse } from "react-icons/pi";
import { IoSearchSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaClipboardCheck } from "react-icons/fa6";
import shoppingCart from "../../assets/img/shoppingcart.png";

const MenuMobile = ({ handleSearch }) => {
    const [search, setSearch] = useState(false);

    return (
        <div>
            <Container>
                <Link to="/produtos-clientes">
                    <PiHouse color="#6E6C54" />
                </Link>
                <button onClick={handleSearch}>
                    <IoSearchSharp color="#6E6C54" />
                </button>

                <Link to="/carrinho-clientes">
                    <img src={shoppingCart} alt="" />
                </Link>

                <Link to="/pedidos-clientes">
                    <FaClipboardCheck fill="#6E6C54" size={20} />
                </Link>
            </Container>
        </div>
    );
};

export default MenuMobile;
