import React from 'react'
import logo_alimentz from '../../assets/img/logo.png'
import icon_bell from '../../assets/img/icon_bell.svg'
import { IoMenuOutline } from "react-icons/io5";

import  styles from './HeaderFornecedores.module.css'


const HeaderFornecedores = ({toggleMenu})  => {



  return (

    <div className={styles.container_header}>
          <div className={styles.section_header}>
          <IoMenuOutline onClick={toggleMenu} className={styles.header_icon}/>
              <img src={logo_alimentz} alt=""className={styles.alimentz_style} />
              <div>
                <img src={icon_bell} className={styles.bell_icon} alt="" />
                <span>2</span>

              </div>  
            </div>
    </div>
  )
}
export default HeaderFornecedores ;
