import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, IconArrow, TitleCarrinho } from "./style";

const Index = ({ title }) => {
    const navigation = useNavigate();

    return (
        <Container>
            <IconArrow
                onClick={() => navigation("/produtos-clientes")}
                fill="#6F6C54"
                style={{ position: "absolute", left: "10px" }}
            />
            <TitleCarrinho>{title}</TitleCarrinho>
        </Container>
    );
};

export default Index;
