import styled from "styled-components";
import { HiMiniArrowLeft } from "react-icons/hi2";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #cdcdcd;
    height: 70px;
`;

export const IconArrow = styled(HiMiniArrowLeft)``;

export const TitleCarrinho = styled.h1`
    font-family: "Poppins", sans-serif;
    color: #0008;
    font-weight: 500px;
    font-size: 16px;
    @media (min-width: 650px) {
        font-size: 18px;
    }
`;
