import styled from "styled-components";

export const ContainerGeral = styled.div`
    padding: 0 8vw;
    margin-top: 1vh;
    display: flex;
    width: 70vw;
    justify-content: space-between;
    @media (max-width: 768px) {
        overflow-y: auto;
        min-height: 50vh;
        padding-bottom: 50px !important;
    }
`;

export const TituloPagina = styled.h1`
    color: var(--background-clientes-escuro);
    font-size: 1.4rem;
    margin-top: 3vh;
    padding: 0 15vw;
`;

export const ContainerCarrinho = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContainerPedidos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    @media (max-width: 768px) {
        gap: 3.5vh;
        padding-bottom: 20px;
    }
`;

export const ContainerResumo = styled.div``;

export const CardResumo = styled.div`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 1.5vh 1.5vw;
    background-color: #fff;
    border: none;
`;

export const TitleResumo = styled.h1`
    font-size: 1.2rem;
`;

export const ContainerDescricaoResumo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3vw;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-right: 20px;
`;

export const DescricaoResumo = styled.h2`
    font-size: 1rem;
    color: var(--background-clientes-claro);
    margin: 0;
`;

export const ContainerBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3vh;
`;

export const BtnResumo = styled.button`
    border-radius: 20px;
    border: 1px solid;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        position: relative;
        top: -1px;
        box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.25);
    }
`;

export const TextoResumo = styled.h1`
    font-size: 1.1rem;
    color: #fff;
    margin: 0;
`;
