import React from "react";
import { CgMathMinus } from "react-icons/cg";
import { CgMathPlus } from "react-icons/cg";
import styles from "./BotoesQuantidade.module.css";

const BotoesQuantidade = () => {
    function increment() {
        var input = document.getElementById("quantity");
        input.value = parseInt(input.value, 10) + 1;
    }

    function decrement() {
        var input = document.getElementById("quantity");
        var value = parseInt(input.value, 10);
        if (!isNaN(value) && value > 0) {
            input.value = value - 1;
        }
    }

    return (
        <>
            <div className={`${styles.quantidade_container} ${styles.table_colum}`}>
                <button onClick={decrement} className={styles.minus_icon}>
                    <CgMathMinus />
                </button>

                <input type="number" value="1" id="quantity" className={styles.quantidade_input}></input>
                <button onClick={increment} className={styles.plus_icon}>
                    <CgMathPlus />
                </button>
            </div>
        </>
    );
};

export default BotoesQuantidade;
