import { React, useEffect, useState, useContext } from "react";
import {
    IconMinus,
    IconPlus,
    ContainerCard,
    ContainerProduto,
    ContainerTexto,
    ContainerTextos,
    ContainerUnitario,
    Img,
    Titulo,
    Descricao,
    DescricaoFrete,
    Codigo,
    ContainerPrecos,
    Preco,
    PrecoKG,
    ContainerBtns,
    BtnQtd,
    BtnAdc,
    InputQtd,
    BtnMinus,
    BtnPlus,
    SpanUnidade,
    BtnAdcMobile,
} from "./styles";
import MsgError from "../MsgError";
import { ClientesContext } from "../../contexts/clientes";

export default function CardsProdutos(props) {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;
    const { showHeader, setShowHeader } = useContext(ClientesContext);

    let produto = props.produto;

    function moneyMask(price) {
        let value = price.toFixed(2);

        value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
        let result = (+value / 100).toFixed(2);
        result = result < 0 || value === "" || result == null ? 0.0 : result;

        result = result.replace(".", ",");

        return result;
    }

    if (!isMobilePage) {
        return (
            <ContainerCard>
                <ContainerProduto onClick={props.onClick}>
                    <ContainerTexto style={{ cursor: "pointer" }} onClick={props.handleCard}>
                        <Img
                            src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${produto.codigo_de_barras}.webp`}
                        />
                        <ContainerTextos>
                            <Titulo>{produto.descricao}</Titulo>
                            <Descricao style={{ maxWidth: "220px", height: "50px" }}>
                                {produto.descricao_resumida}
                            </Descricao>
                            <DescricaoFrete>Envio em até {produto.prazo_entrega} dia(s)</DescricaoFrete>
                        </ContainerTextos>
                    </ContainerTexto>
                    <ContainerTexto style={{ alignItems: "center", justifyContent: "space-between" }}>
                        <Descricao style={{ maxWidth: "55%", fontSize: "0.7rem" }}>
                            Vendido por {produto.nome}
                        </Descricao>
                        <ContainerPrecos>
                            <Preco>R${moneyMask(produto.preco_venda)}</Preco>
                            <ContainerUnitario>
                                <Preco>{moneyMask(produto.preco_unitario)}/</Preco>
                                <SpanUnidade>{produto.unidade_medida.toLowerCase()}</SpanUnidade>
                            </ContainerUnitario>
                        </ContainerPrecos>
                    </ContainerTexto>
                    <ContainerBtns>
                        <BtnQtd>
                            <BtnMinus onClick={() => props.handleQuantidade(produto, "minus")}>
                                <IconMinus />
                            </BtnMinus>
                            <InputQtd
                                type="number"
                                value={produto.quantidade}
                                onChange={(text) => {
                                    props.handleQuantidade(produto, text.target.value);
                                }}
                            />
                            <BtnPlus
                                onClick={() => {
                                    props.handleQuantidade(produto, "plus");
                                }}
                            >
                                <IconPlus />
                            </BtnPlus>
                        </BtnQtd>
                        <BtnAdc onClick={() => [props.storeCarrinho()]}>Adicionar</BtnAdc>
                    </ContainerBtns>
                </ContainerProduto>
            </ContainerCard>
        );
    } else {
        return (
            <ContainerCard>
                <ContainerProduto>
                    <div onClick={props.onClick}>
                        <ContainerTexto style={{ cursor: "pointer" }} onClick={props.handleCard}>
                            <Img
                                src={`https://909260639911-alimenz-imagens.s3.sa-east-1.amazonaws.com/${produto.codigo_de_barras}.webp`}
                            />
                            <ContainerTextos>
                                <Titulo>{produto.descricao}</Titulo>
                                <Descricao style={{ maxWidth: "220px", height: "50px" }}>
                                    {produto.descricao_resumida}
                                </Descricao>
                                <DescricaoFrete>Envio em até {produto.prazo_entrega} dia(s)</DescricaoFrete>
                            </ContainerTextos>
                        </ContainerTexto>
                        <ContainerTexto style={{ alignItems: "center", justifyContent: "space-between" }}>
                            <Descricao style={{ maxWidth: "55%", fontSize: "0.7rem" }}>
                                Vendido por {produto.nome}
                            </Descricao>
                            <ContainerPrecos>
                                <Preco>R${moneyMask(produto.preco_venda)}</Preco>
                                <ContainerUnitario>
                                    <Preco>{moneyMask(produto.preco_unitario)}/</Preco>
                                    <SpanUnidade>{produto.unidade_medida.toLowerCase()}</SpanUnidade>
                                </ContainerUnitario>
                            </ContainerPrecos>
                        </ContainerTexto>
                        <BtnAdcMobile onClick={() => props.storeCarrinho()}>Adicionar</BtnAdcMobile>
                    </div>
                </ContainerProduto>
            </ContainerCard>
        );
    }
}
