import styled from "styled-components";

export const ContainerCards = styled.div`
    width: 100%;
`;

export const ContainerCard = styled.div`
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: inline-block;
    padding: 1.5vh 1.5vw;
    background-color: #fff;
    border: none;
    min-width: 40vw;
    transition: all 0.5s;
    @media (max-width: 768px) {
        display: flex;
        padding: 1.5vh 1.5vw;
        flex-direction: column;
        margin: 0 auto;
        width: 80%;
    }
`;

export const ContainerVisible = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vh;
`;

export const ContainerHidden = styled.div`
    flex-direction: column;
    width: 95%;
`;

export const ContainerInfos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Infos = styled.h1`
    font-size: 0.8rem;
    color: #999999;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    margin: 0;
    @media (max-width: 475px) {
        font-size: 10px;
    }
`;

export const Titulo = styled.h1`
    font-size: 1rem;
    color: var(--background-clientes-escuro);
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    margin: 0;
    @media (max-width: 425px) {
        font-size: 12px !important;
    }
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const Descricao = styled.h1`
    font-size: 1rem;
    color: var(--background-clientes);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    margin: 0;
    @media (max-width: 425px) {
        font-size: 10px !important;
    }
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

export const ListaProduto = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 425px) {
        display: grid;
        grid-template-columns: 2fr 6fr 2fr;
        gap: 5px;
        padding-left: 18px;
    }
    @media (max-height: 650px) {
        padding-left: 20px !important;
    }
    @media (max-width: 768px) {
        padding-left: 25px;
    }
`;

export const Imagem = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    max-width: 5vw;
    @media (max-width: 425px) {
        max-width: 10vw;
    }
`;

export const DescricaoProduto = styled.h1`
    font-size: 1rem;
    color: var(--preto);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    margin: 0;
    max-width: 22ch;
`;

export const BtnQtd = styled.div`
    padding: 0;
    border-radius: 20px;
    background-color: var(--branco);
    display: flex;
    flex-direction: row;
    height: 3vh;
    @media (max-width: 425px) {
        width: 80px !important;
    }
    @media (min-width: 426px) {
        width: 100px;
    }
    @media (max-width: 768px) {
        height: 4vh;
        border: 1px solid #cdcdcd;
        justify-content: space-evenly;
    }
`;

export const TextoBtnQtd = styled.h2`
    font-size: 0.8rem;
    color: var(--background-clientes);
`;

export const Textdicionar = styled.p`
    font-size: 10px;
    font-weight: 500;
    color: #6f6c54;
`;

export const Linha = styled.h2`
    width: 80%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.3);
    align-self: center;
`;

export const BtnResumo = styled.div`
    border-radius: 20px;
    border: 1px solid;
    padding: 5px 15px;
`;

export const TextoResumo = styled.h1`
    font-size: 0.9rem;
    color: #fff;
    margin: 0;
`;

export const BtnMinus = styled.button`
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid var(--background-clientes-claro);
    align-items: center;
    @media (max-width: 768px) {
        border: none;
    }
`;

export const BtnPlus = styled.button`
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid var(--background-clientes-claro);
    align-items: center;
    @media (max-width: 768px) {
        border: none;
        width: 100%;
    }
`;

export const InputQtd = styled.input`
    width: 2vw;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
    border-top: 1px solid var(--background-clientes-claro);
    border-bottom: 1px solid var(--background-clientes-claro);
    outline-color: var(--background-clientes-claro);
    @media (max-width: 768px) {
        width: 100%;
        border: none;
    }
`;
