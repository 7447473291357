import React, { useContext, useState, useEffect, useRef } from "react";
import { ClientesContext } from "../../contexts/clientes";
import HeaderClientes from "../../components/HeaderClientes";
import HeaderClientesMobile from "../../components/HeaderClientesMobile";
import Slider from "react-slick";
import {
    ContainerCards,
    ImgCard,
    TextCard,
    TextCardMobile,
    ContainerImg,
    ContainerCategorias,
    ContainerMobile,
    ContainerCategoriasMobile,
    ContainerCardsMobile,
    ContainerTextMobile,
    ImgCardMobile,
    ContainerFiltroPesquisa,
    ContainerInput,
    InputProdutos,
    ButtonFilter,
} from "./styles";
import { createUseStyles } from "react-jss";
import ProdutosSugeridos from "../../components/ProdutosSugeridos";
import axios from "axios";
import HTTP from "../../http/http";
import ArrowSlideLeft from "../../components/ArrowSlideClientes/ArrowSlideLeft";
import ArrowSlideRight from "../../components/ArrowSlideClientes/ArrowSlideRight";
import { LuSlidersHorizontal } from "react-icons/lu";
import { AuthContext } from "../../contexts/auth";
import MenuFooterMobile from "../../components/MenuFooterMobile";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { handleSearch } from "../../components/MenuFooterMobile";

export default function Clientes() {
    const { token, device, userDimensions } = useContext(AuthContext);
    const {
        changeFiltro,
        titleProdutos,
        selectProduct,
        productEnter,
        selectedProduct,
        carrinho,
        listaFornecedores,
        showHeader,
        searchProdutos,
        produtos,
    } = useContext(ClientesContext);
    const [categorias, setCategorias] = useState([]);
    const sliderRef = useRef(null);
    const location = useLocation();
    const navigation = useNavigate();
    const [selectedText, setSelectedText] = useState("");
    const [selectedMarca, setSelectedMarca] = useState(null);
    const [search, setSearch] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (search && inputRef.current) {
            inputRef.current.focus();
        }
    }, [search]);

    const handleSearch = () => {
        setSearch((prevSearch) => !prevSearch);
    };

    const useStyles = createUseStyles({
        sliderContainer: {
            "& .slick-list": {
                paddingTop: "2vh",
                paddingBottom: "1vh",
                paddingLeft: "3vw",
                paddingRight: "6vw",
                marginLeft: "4vw",
                marginRight: "6vw",
            },
            "& .slick-track": {
                display: "flex",
            },
        },
        sliderContainerMobile: {
            "& .slick-list": {
                paddingTop: "1vh",
                paddingBottom: "1vh",
                marginLeft: "5vw",
                marginRight: "5vw",
            },
            "& .slick-track": {
                display: "flex",
                gap: "10px",
            },
        },
    });
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const classes = useStyles();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 4,
        className: classes.sliderContainer,
    };

    const settingsMobile = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        className: classes.sliderContainerMobile,
    };

    useEffect(() => {
        axios.all([
            HTTP.get(`${process.env.REACT_APP_BASE_HTTP}/categorias`, { headers: { authorization: "Bearer " + token } })
                .then((response) => {
                    setCategorias(
                        response.data.sort((a, b) => {
                            if (a.categorias < b.categorias) return -1;
                        })
                    );
                })
                .catch((err) => console.log(1, err)),
        ]);
    }, []);

    function resetProdutos() {
        navigation("/produtos-clientes");
        changeFiltro("reset", "reset");
        setSelectedText("");
        setSelectedMarca(null);
        selectProduct(null);
    }

    if (device != "mobile") {
        return (
            <div>
                <HeaderClientes />
                <ContainerCategorias>
                    <ArrowSlideLeft onClick={prevSlide} />
                    <Slider ref={sliderRef} {...settings} style={{ padding: "10px, 20px", width: "99%" }}>
                        {categorias.map((element, index) => {
                            return (
                                <ContainerCards
                                    key={index}
                                    id="cards"
                                    onClick={() => {
                                        changeFiltro("categoria_2", element.categorias);
                                        selectProduct(null);
                                    }}
                                >
                                    <ContainerImg>
                                        <ImgCard
                                            src={`https://909260639911-alimenz-categorias.s3.sa-east-1.amazonaws.com/${element.categorias}.webp`}
                                            alt="categoria"
                                        />
                                    </ContainerImg>
                                    <TextCard>{element.categorias}</TextCard>
                                </ContainerCards>
                            );
                        })}
                    </Slider>
                    <ArrowSlideRight onClick={nextSlide} />
                </ContainerCategorias>
                <ProdutosSugeridos tituloPagina={titleProdutos} />
            </div>
        );
    } else {
        return (
            <>
                <ContainerMobile>
                    {showHeader && <HeaderClientesMobile clearFilter={resetProdutos} />}
                    {showHeader && (
                        <>
                            <ContainerFiltroPesquisa>
                                {location.pathname == "/produtos-clientes" && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "1vw",
                                            width: "100%",
                                        }}
                                    >
                                        <ContainerInput>
                                            <FaSearch
                                                style={{
                                                    left: userDimensions.width * 0.06,
                                                    margin: "0",
                                                    marginRight: "-7px",
                                                }}
                                                color="var(--background-clientes)"
                                                size={17}
                                            />
                                            {
                                                <InputProdutos
                                                    ref={inputRef}
                                                    placeholder="Nome do produto"
                                                    type="text"
                                                    onChange={(text) => [
                                                        searchProdutos(text.target.value),
                                                        setSelectedText(text.target.value),
                                                    ]}
                                                    value={selectedText}
                                                    style={{
                                                        fontSize: "12px",
                                                    }}
                                                />
                                            }
                                        </ContainerInput>
                                        {/* <DatalistInput
                                    placeholder="Filtrar Fornecedor"
                                    onSelect={(a) => {
                                        changeFiltro("nome", a.value);
                                        setSelectedMarca(a.value);
                                    }}
                                    value={selectedMarca}
                                    items={listaFornecedores.map((item, index) => {
                                        return {
                                            key: index,
                                            value: item.value,
                                        };
                                    })}
                                    style={{ width: userDimensions.width * 0.12 }}
                                /> */}
                                        {/* <ButtonFilter
                                            disabled
                                            onClick={() => {
                                                resetProdutos();
                                            }}
                                        >
                                            <LuSlidersHorizontal color="#0005" size={17} />
                                            Filtros
                                        </ButtonFilter> */}
                                    </div>
                                )}
                            </ContainerFiltroPesquisa>

                            <ContainerCategoriasMobile>
                                <ArrowSlideLeft handleClick={prevSlide} />
                                <Slider
                                    ref={sliderRef}
                                    {...settingsMobile}
                                    style={{ padding: "5px, 10px", width: "99%" }}
                                >
                                    {categorias.map((element, index) => {
                                        return (
                                            <ContainerCardsMobile
                                                key={index}
                                                id="cards"
                                                onClick={() => {
                                                    changeFiltro("categoria_2", element.categorias);
                                                    selectProduct(null);
                                                }}
                                            >
                                                <ImgCardMobile
                                                    src={`https://909260639911-alimenz-categorias.s3.sa-east-1.amazonaws.com/${element.categorias}.webp`}
                                                    alt="categoria"
                                                />
                                                <ContainerTextMobile>
                                                    <TextCardMobile>{element.categorias}</TextCardMobile>
                                                </ContainerTextMobile>
                                            </ContainerCardsMobile>
                                        );
                                    })}
                                </Slider>
                                <ArrowSlideRight onClick={nextSlide} />
                            </ContainerCategoriasMobile>
                        </>
                    )}
                    <ProdutosSugeridos tituloPagina={titleProdutos} />
                </ContainerMobile>
                <MenuFooterMobile handleSearch={handleSearch} />
            </>
        );
    }
}
