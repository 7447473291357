import styled from "styled-components";

export const ContainerMobile = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    z-index: 1;
    position: relative;
`;

export const Imagem = styled.img`
    width: 35%;
    max-width: 180px;
`;

export const ContainerImageUser = styled.div`
    display: grid;
    column-gap: 10px;
    grid-template-columns: 0.3fr 1fr;
    padding: 5px;
`;

export const ContainerImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        width: 100%;
        max-width: 80px;
    }
`;

export const ButtonHelp = styled.a`
    text-decoration: none;
    background-color: violet;
    width: 100%;
    max-width: 250px;
    background-color: #875465;
    border: none;
    border-radius: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    color: #fff;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    padding-left: 10px;
    > svg {
        height: 70%;
    }
    &:hover {
        background-color: #535033;
    }
`;

export const ContainerHelper = styled.div`
    display: grid;
    gap: 10px;
    margin-bottom: 25px;
`;

export const ContainerTexts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h2,
    p {
        font-size: 12px;
        color: #4f4f4f;
        font-family: "Poppins", sans-serif;
        margin: 0;
    }
    @media (min-width: 426px) {
        > h2,
        p {
            text-align: center;
        }
    }
`;

export const TextOut = styled.p`
    text-align: center;
    color: var(--background-clientes-escuro);
    padding: 0px;
    margin: 0 auto;
`;
