import React, { useContext } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { AuthContext } from "../../contexts/auth";

export default function ArrowSlideRight({ onClick }) {
    const { device } = useContext(AuthContext);

    return (
        <IoIosArrowForward
            size={device == "mobile" ? "30px" : "60px"}
            style={{
                position: "absolute",
                cursor: "pointer",
                zIndex: 1,
                right: device == "mobile" ? "0vw" : "1vw",
                color: "#999999",
                padding: device == "mobile" ? "0px" : "",
            }}
            onClick={onClick}
        />
    );
}
